import React, { Component } from "react";
import { withRouter } from "react-router";
import { slide as Menu } from "react-burger-menu";
import {
  FaFileSignature,
  FaComments,
  FaLanguage,
  FaKey,
  FaRegCopyright,
  FaRegSmile
} from "react-icons/fa";
import { Modal, ModalBody, ModalTitle, Button } from "react-bootstrap";
import { IntlConsumer } from "./IntlContext";
import { FormattedMessage } from "react-intl";
import { ai } from "./_helper/TelemetryService";
import Feedback from "./Feedback";

import {getSupport} from './_helper/BrandedContent'
import {getTermsAndConditionsEN} from './_helper/BrandedContent'
import {getTermsAndConditionsZH} from './_helper/BrandedContent'
import {getPrivacyPoliciesEN} from './_helper/BrandedContent'
import {getPrivacyPoliciesZH} from './_helper/BrandedContent'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: "",
      menuOpen: false,
      tcPopupShow: false,
      ppPopupShow: false,
      supportPopupShow: false,
      feedbackModalShow: false,
      support:"",
      TermsEN:"", TermsZH:"",
      PrivacyEN:"", PrivacyZH:"" 
    };
    this.handleTcPopupClose = this.handleTcPopupClose.bind(this);
    this.showTcPopup = this.showTcPopup.bind(this);
    this.showPpPopup = this.showPpPopup.bind(this);
    this.handlePpPopupClose = this.handlePpPopupClose.bind(this);
    this.showSupportPopup = this.showSupportPopup.bind(this);
    this.handleSupportPopupClose = this.handleSupportPopupClose.bind(this);
    this.handleFeedbackModalOpen = this.handleFeedbackModalOpen.bind(this);
  }

  handleTcPopupClose() {
    ai.trackEvent("Close popup: T&Cs Policy", { popup: "tcs" });
    this.setState({ tcPopupShow: false });
  }

  handlePpPopupClose() {
    ai.trackEvent("Close popup: Privacy Policy", { popup: "PP" });
    this.setState({ ppPopupShow: false });
  }

  handleSupportPopupClose() {
    ai.trackEvent("Close popup: Support", { popup: "Support" });
    this.setState({ supportPopupShow: false });
  }

  showTcPopup(event) {
    event.preventDefault();
    ai.trackEvent("Open popup: T&Cs Policy", { popup: "tcs" });
    this.setState({ menuOpen: false, tcPopupShow: true });
  }

  showPpPopup(event) {
    event.preventDefault();
    ai.trackEvent("Open popup: Privacy Policy", { popup: "PP" });
    this.setState({ menuOpen: false, ppPopupShow: true });
  }

  showSupportPopup(event) {
    event.preventDefault();
    ai.trackEvent("Open popup: Support", { popup: "Support" });
    this.setState({ menuOpen: false, supportPopupShow: true });
  }

  handleStateChange(state) {
    ai.trackEvent("Menu: " + state.isOpen);
    this.setState({ menuOpen: state.isOpen });
  }
  handleFeedbackModalOpen() {
    this.setState({ feedbackModalShow: true });
  }

  handleFeedbackModalClose() {
    this.setState({ feedbackModalShow: false });
  }

  render() {
    const  {tenantId}  = this.props;
    const SupportPage = getSupport(tenantId)
    const TermsEN = getTermsAndConditionsEN(tenantId)
    const TernsZH = getTermsAndConditionsZH(tenantId)
    const PrivacyEN = getPrivacyPoliciesEN(tenantId)
    const PrivacyZH = getPrivacyPoliciesZH(tenantId)


    return (
      <div>

        {process.env.REACT_APP_MODE !== "PROD" ? (
          <span style={{ color: "red", background: 'yellow', fontWeight: "bold", position: "absolute", top: 10, left: "50%", zIndex: 99999, transform: 'translate(-50%, -50%)' }}>
            {process.env.REACT_APP_MODE} ENV - TESTING ONLY!
          </span>
        ) : null}

        <div className="row">
          <div className=" pull-left col logoPosition">
            <Menu
              isOpen={this.state.menuOpen}
              onStateChange={state => this.handleStateChange(state)}
            >
              <a
                onClick={this.showSupportPopup}
                id="btnSupport"
                href=""
                className="bm-item align-middle"
              >
                <FaComments
                  id="icon_support"
                  className="iconStyleMenu iconLarge"
                />
                <FormattedMessage
                  id="app.menu.support"
                  defaultMessage="Support"
                  description="Label text of support link in menu"
                />
              </a>

              <div className="bm-menu-divider mt-3 mb-3" />
              <div>
                <FaLanguage id="icon_loc" className="iconStyleMenu iconLarge" />
                <span>
                  <FormattedMessage
                    id="app.menu.language"
                    defaultMessage="Language"
                    description="Label text of language link in menu"
                  />
                </span>
              </div>
              <IntlConsumer>
                {({ switchToEnglish, switchToChineseSimplified, switchToChineseTraditional, switchToJapanese, switchToKorean, switchToPortuguese, switchToVietnamese, switchToArabic, switchToThai, locale }) => (
                  <React.Fragment>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "zh-CN" ? "selectedLoc" : null)
                        }
                        onClick={switchToChineseSimplified}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        简体字
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "zh-TW" ? "selectedLoc" : null)
                        }
                        onClick={switchToChineseTraditional}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        繁體字
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "en" ? "selectedLoc" : null)
                        }
                        onClick={switchToEnglish}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        English
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "ja" ? "selectedLoc" : null)
                        }
                        onClick={switchToJapanese}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        日本語
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "ko" ? "selectedLoc" : null)
                        }
                        onClick={switchToKorean}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        한국어
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "pt" ? "selectedLoc" : null)
                        }
                        onClick={switchToPortuguese}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        Português
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "vi" ? "selectedLoc" : null)
                        }
                        onClick={switchToVietnamese}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        Tiếng Việt
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "ar" ? "selectedLoc" : null)
                        }
                        onClick={switchToArabic}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        العربية
                      </Button>
                    </div>
                    <div>
                      <Button
                        className={
                          "bm-menu-linkbutton " +
                          (locale === "th" ? "selectedLoc" : null)
                        }
                        onClick={switchToThai}
                      >
                        <FaLanguage
                          id="icon_tc"
                          className="iconStyleMenuHidden iconLarge"
                        />
                        ไทย
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </IntlConsumer>
              <div className="fixToBottom">
                <div className="bm-menu-divider mb-1" />
                <a
                  onClick={this.showTcPopup}
                  href=""
                  id="btnTCs"
                  className="bm-item align-middle"
                  style={{ fontSize: "70%" }}
                >
                  <FaFileSignature
                    id="icon_tc"
                    className="iconStyleMenu iconSmall"
                  />
                  <FormattedMessage
                    id="app.menu.termsandconditions"
                    defaultMessage="Terms & Conditions"
                    description="Label text of terms and conditions link in menu"
                  />
                </a>
                <br />
                <a
                  onClick={this.showPpPopup}
                  href=""
                  id="btnPrivacyPolicy"
                  className="bm-item align-middle"
                  style={{ fontSize: "70%" }}
                >
                  <FaKey id="icon_tc" className="iconStyleMenu iconSmall" />
                  <FormattedMessage
                    id="app.menu.privacypolicy"
                    defaultMessage="Privacy Policy"
                    description="Label text of privacy policy link in menu"
                  />
                </a>
                <div className="bm-menu-divider mb-1" />
                <div className="text-center copyrightfooter">
                  <span>
                    <FormattedMessage
                      id="app.menu.version"
                      defaultMessage="Version"
                      description="Label text of version in menu"
                    />
                    <span id="txtversion">
                      &nbsp;{process.env.REACT_APP_VERSION}
                    </span>
                  </span>
                  {process.env.REACT_APP_MODE !== "PROD" ? (
                    <span style={{ color: "white", fontWeight: "bold" }}>
                      - {process.env.REACT_APP_MODE}
                    </span>
                  ) : null}
                  <br />
                  <FaRegCopyright id="icon_tc" />{" "}
                  <span id="txtcopyright" className="align-middle">
                    2019-2022, Sivantos Pte. Ltd. or its affiliates
                  </span>
                  <br />
                  {process.env.REACT_APP_REGION === "CHINA" ? (
                    <>
                      <a className="lightLink" href="https://www.miibeian.gov.cn/">
                        苏ICP备15039846号
                  </a>
                      <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        -
                  </span>
                      <a
                        className="lightLink"
                        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002002180"
                      >
                        苏公网安备32059002002180号
                  </a>
                    </>
                  ) : null}
                </div>
              </div>
            </Menu>
          </div>
          <div id="check" className="text-center">
            <button id="btnFeedback"
              className="feedbackButton text-justify" 
              onClick={this.handleFeedbackModalOpen}
            >
              <div>
                <FaRegSmile style={{ display: "inline-block" }} className="feedbackButtonSmiley" /><span > 
                  <FormattedMessage
                      id="app.shared.feedback"
                      defaultMessage="Feedback"
                      description="Label text of feedback button"
                    /></span>
              </div>
            </button>
            {this.state.feedbackModalShow ? (
              <Feedback
                intiatedPage={this.props.intiatedPage}
                handleFeedbackModalClose={() => this.handleFeedbackModalClose()}
              />
            ) : null}
          </div>
          <div className="text-right col">
            <span className="logoContainer">
              <img src={require(`../Assets/${tenantId}/Images/logo.png`)} className="logo mt-3" alt="logo" />
            </span>
          </div>
        </div>
        <Modal
          show={this.state.tcPopupShow}
          centered
          id="modalTc"
          dialogClassName="modal-lg"
          backdrop="static"
          onHide={this.handleTcPopupClose}
        >
          <Modal.Header
            className="d-block text-center"
            style={{ borderBottom: "0 none" }}
          >
            <ModalTitle>
              <div className="legalHeader">
                <FormattedMessage
                  id="app.menu.termsandconditionslicenseagreement"
                  defaultMessage="TERMS AND CONDITIONS and LICENSE AGREEMENT"
                  description="header of t&cs and la popup"
                />
              </div>
            </ModalTitle>
          </Modal.Header>
          <ModalBody style={{ maxHeight: "60vh", overflowY: "auto" }}>
            <IntlConsumer>
              {({ locale }) =>
                locale.startsWith('zh') ? (
                  <TernsZH />
                ) : (
                  <TermsEN />
                )
              }
            </IntlConsumer>
          </ModalBody>
          <Modal.Footer
            className="d-block text-center"
            style={{ borderTop: "0 none" }}
          >
            <div className="text-center">
              <button
                className="largeButtonPopup"
                id="btnModalClose"
                type="button"
                onClick={this.handleTcPopupClose}
              >
                <FormattedMessage
                  id="app.general.close"
                  defaultMessage="Close"
                  description="Button caption for Close buttons"
                />
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.ppPopupShow}
          centered
          id="modalPp"
          dialogClassName="modal-lg"
          backdrop="static"
          onHide={this.handlePpPopupClose}
        >
          <Modal.Header
            className="d-block text-center"
            style={{ borderBottom: "0 none" }}
          >
            <Modal.Title>
              <div className="legalHeader">
                <FormattedMessage
                  id="app.menu.privacypolicy"
                  defaultMessage="Privacy Policy"
                  description="Label text of privacy policy link in menu"
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <ModalBody style={{ maxHeight: "60vh", overflowY: "auto" }}>
            <IntlConsumer>
              {({ locale }) =>
                locale.startsWith('zh') ? <PrivacyZH /> : <PrivacyEN />
              }
            </IntlConsumer>
          </ModalBody>
          <Modal.Footer
            className="d-block text-center"
            style={{ borderTop: "0 none" }}
          >
            <div className="text-center">
              <button
                className="largeButtonPopup"
                id="btnModalClose"
                type="button"
                onClick={this.handlePpPopupClose}
              >
                <FormattedMessage
                  id="app.general.close"
                  defaultMessage="Close"
                  description="Button caption for Close buttons"
                />
              </button>
            </div>
          </Modal.Footer>
        </Modal>

         <Modal
          show={this.state.supportPopupShow}
          centered
          id="modalSupport"
          backdrop="static"
          onHide={this.handleSupportPopupClose}
        >
          <Modal.Header
            className="d-block text-center"
            style={{ borderBottom: "0 none" }}
          >
            <ModalTitle>
              <div id="supportHeader" className="txt-bold">
                <FormattedMessage
                  id="app.menu.support"
                  defaultMessage="Support"
                  description="Label text of support link in menu"
                />
              </div>
            </ModalTitle>
          </Modal.Header>
          <ModalBody style={{ maxHeight: "70vh", overflowY: "auto" }}>

          
             <div id="supportContent" className="text-center">
            <SupportPage/>
            </div> 
          </ModalBody>
          <Modal.Footer
            className="d-block text-center"
            style={{ borderTop: "0 none" }}
          >
            <div className="text-center">
              <button
                className="largeButtonPopup"
                id="btnModalClose"
                type="button"
                onClick={this.handleSupportPopupClose}
              >
                <FormattedMessage
                  id="app.general.ok"
                  defaultMessage="OK"
                  description="Button caption for OK buttons"
                />
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Header);
