import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const createTelemetryService = () => {
    let reactPlugin = null;
    let appInsights = null;
    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_INSTRUMENTATIONKEY,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            appId: "HomeFittingFrontend",
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: {

                }
            }
        }
    });

    appInsights.loadAppInsights();


    //adds role name for every track item to make filtering easier
    var telemetryInitializer = (envelope) => {
        envelope.tags["ai.cloud.role"] = "HomeFittingUI";
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);

    var pageViewDurationStartTime;

    const trackPageView = (page) => {
        page = "myVibe - " + page;
        appInsights.trackPageView({ name: page });
    }

    const trackPageViewWithTime = (page, durationInMs) => {
        page = "" + page;
        appInsights.trackMetric({ name: "page view duration", average: durationInMs }, {page: page});
        
    }

    const traceDebug = (msg) => {
        appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Verbose });
    }

    const traceInfo = (msg, custDim) => {
        appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Information }, custDim);
    }

    const startPageViewTracking = () =>{
        pageViewDurationStartTime = new Date();
    }

    const stopPageViewTracking = (page) => {
        if (pageViewDurationStartTime == null) return;
        appInsights.trackMetric({ name: "page view duration", average: new Date() - pageViewDurationStartTime }, { page: page });

        pageViewDurationStartTime = null;
    }


    const traceError = (msg, error, custDim) => {
        custDim = {error, ...custDim}
        appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Information }, custDim);
    }

    const trackEvent = (event, custDim) => {
        appInsights.trackEvent({name: event}, custDim);
    }


    return { reactPlugin, appInsights, trackPageView, startPageViewTracking, stopPageViewTracking, trackPageViewWithTime, traceDebug, traceInfo, traceError, trackEvent };
};

export const ai = createTelemetryService();