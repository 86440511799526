import offboarding0abe829d3c29470098dcf3c709f85514 from "../../Assets/0abe829d-3c29-4700-98dc-f3c709f85514/files/offboarding";
import offboarding4f6c96115ca6424bb6755abfc45a44d4 from "../../Assets/4f6c9611-5ca6-424b-b675-5abfc45a44d4/files/offboarding";
import offboarding68ad6406f094411e8b9955d02e6cc2a5 from "../../Assets/68ad6406-f094-411e-8b99-55d02e6cc2a5/files/offboarding";
import offboarding70f1aeebf07f4d62b055e77b0c461e62 from "../../Assets/70f1aeeb-f07f-4d62-b055-e77b0c461e62/files/offboarding";
import offboardinga7ccee845b034a81bbe28b618c0de24a from "../../Assets/a7ccee84-5b03-4a81-bbe2-8b618c0de24a/files/offboarding";
import offboardingc9a894c18f6345d4ae41bc216ea49392 from "../../Assets/c9a894c1-8f63-45d4-ae41-bc216ea49392/files/offboarding";
import offboardingd264d0ee88a144a281348ebe85910bd8 from "../../Assets/d264d0ee-88a1-44a2-8134-8ebe85910bd8/files/offboarding";
import offboardingf6b8108a563a4f80b2ce4d6f95929d48 from "../../Assets/f6b8108a-563a-4f80-b2ce-4d6f95929d48/files/offboarding";
import offboardingde2251a1b6da45c8813a96153b878c51 from "../../Assets/de2251a1-b6da-45c8-813a-96153b878c51/files/offboarding";
import offboarding5435117cd00711ec9d640242ac120002 from "../../Assets/5435117c-d007-11ec-9d64-0242ac120002/files/offboarding";
import offboarding1f29e1ea536b11ee8c990242ac120002 from "../../Assets/1f29e1ea-536b-11ee-8c99-0242ac120002/files/offboarding";
import offboarding4ce08156dbfc4e50b92eee7458de457f from "../../Assets/4ce08156-dbfc-4e50-b92e-ee7458de457f/files/offboarding";
import offboardingd4b2ed4c9b6b49b49c07108168c3c0ac from "../../Assets/d4b2ed4c-9b6b-49b4-9c07-108168c3c0ac/files/offboarding";
import offboardingf4f2e21e7abc4151a1a370e89f91a28b from "../../Assets/f4f2e21e-7abc-4151-a1a3-70e89f91a28b/files/offboarding";
import offboardingfb17253d80b345fcb31a3407127c5fbd from "../../Assets/fb17253d-80b3-45fc-b31a-3407127c5fbd/files/offboarding";
import offboardingef6ff7d5888144d28010faee41262123 from "../../Assets/ef6ff7d5-8881-44d2-8010-faee41262123/files/offboarding";

import SupportVibeO2O from "../../Assets/5435117c-d007-11ec-9d64-0242ac120002/files/support";
import SupportVibeChina from "../../Assets/0abe829d-3c29-4700-98dc-f3c709f85514/files/support";
import SupportVibeRow from "../../Assets/4f6c9611-5ca6-424b-b675-5abfc45a44d4/files/support";
import SupportWSAAudiology from "../../Assets/68ad6406-f094-411e-8b99-55d02e6cc2a5/files/support";
import SupportComunicare from "../../Assets/70f1aeeb-f07f-4d62-b055-e77b0c461e62/files/support";
import SupportBaodao from "../../Assets/a7ccee84-5b03-4a81-bbe2-8b618c0de24a/files/support";
import SupportChinaDistributors from "../../Assets/c9a894c1-8f63-45d4-ae41-bc216ea49392/files/support";
import SupportFosun from "../../Assets/d264d0ee-88a1-44a2-8134-8ebe85910bd8/files/support";
import SupportVibeJP from "../../Assets/f6b8108a-563a-4f80-b2ce-4d6f95929d48/files/support";
import SupportVibeJPFirstFit from "../../Assets/1f29e1ea-536b-11ee-8c99-0242ac120002/files/support";
import SupportVibeEgypt from "../../Assets/de2251a1-b6da-45c8-813a-96153b878c51/files/support";
import SupportSaudi from "../../Assets/4ce08156-dbfc-4e50-b92e-ee7458de457f/files/support";
import SupportTWHK from "../../Assets/d4b2ed4c-9b6b-49b4-9c07-108168c3c0ac/files/support";
import SupportSamsungHospital from "../../Assets/f4f2e21e-7abc-4151-a1a3-70e89f91a28b/files/support";
import SupportSGnanyang from "../../Assets/fb17253d-80b3-45fc-b31a-3407127c5fbd/files/support";
import SupportvibeIndia from "../../Assets/ef6ff7d5-8881-44d2-8010-faee41262123/files/support";

import TermsVibeChinaEN from "../../Assets/0abe829d-3c29-4700-98dc-f3c709f85514/files/TermsAndConditions/TermsAndConditions_en";
import TermsVibeChinaZH from "../../Assets/0abe829d-3c29-4700-98dc-f3c709f85514/files/TermsAndConditions/TermsAndConditions_zh";
import PrivacyVibeChinaEN from "../../Assets/0abe829d-3c29-4700-98dc-f3c709f85514/files/PrivacyPolicy/PrivacyPolicy_en";
import PrivacyVibeChinaZH from "../../Assets/0abe829d-3c29-4700-98dc-f3c709f85514/files/PrivacyPolicy/PrivacyPolicy_zh";

export function getOffboardingContent(tenantId, deviceCount) {
  switch (tenantId) {
    case "5435117c-d007-11ec-9d64-0242ac120002":
      return offboarding5435117cd00711ec9d640242ac120002(deviceCount);    
    case "0abe829d-3c29-4700-98dc-f3c709f85514":
      return offboarding0abe829d3c29470098dcf3c709f85514(deviceCount);
    case "4f6c9611-5ca6-424b-b675-5abfc45a44d4":
      return offboarding4f6c96115ca6424bb6755abfc45a44d4(deviceCount);
    case "68ad6406-f094-411e-8b99-55d02e6cc2a5":
      return offboarding68ad6406f094411e8b9955d02e6cc2a5(deviceCount);
    case "70f1aeeb-f07f-4d62-b055-e77b0c461e62":
      return offboarding70f1aeebf07f4d62b055e77b0c461e62(deviceCount);
    case "a7ccee84-5b03-4a81-bbe2-8b618c0de24a":
      return offboardinga7ccee845b034a81bbe28b618c0de24a(deviceCount);
    case "c9a894c1-8f63-45d4-ae41-bc216ea49392":
      return offboardingc9a894c18f6345d4ae41bc216ea49392(deviceCount);
    case "d264d0ee-88a1-44a2-8134-8ebe85910bd8":
      return offboardingd264d0ee88a144a281348ebe85910bd8(deviceCount);
    case "f6b8108a-563a-4f80-b2ce-4d6f95929d48":
      return offboardingf6b8108a563a4f80b2ce4d6f95929d48(deviceCount);
    case "1f29e1ea-536b-11ee-8c99-0242ac120002":
      return offboarding1f29e1ea536b11ee8c990242ac120002(deviceCount);
    case "de2251a1-b6da-45c8-813a-96153b878c51":
      return offboardingde2251a1b6da45c8813a96153b878c51(deviceCount);
    case "4ce08156-dbfc-4e50-b92e-ee7458de457f":
      return offboarding4ce08156dbfc4e50b92eee7458de457f(deviceCount);
    case "d4b2ed4c-9b6b-49b4-9c07-108168c3c0ac":
      return offboardingd4b2ed4c9b6b49b49c07108168c3c0ac(deviceCount);
    case "f4f2e21e-7abc-4151-a1a3-70e89f91a28b":
      return offboardingf4f2e21e7abc4151a1a370e89f91a28b(deviceCount);
    case "fb17253d-80b3-45fc-b31a-3407127c5fbd":
      return offboardingfb17253d80b345fcb31a3407127c5fbd(deviceCount);
    case "ef6ff7d5-8881-44d2-8010-faee41262123":
      return offboardingef6ff7d5888144d28010faee41262123(deviceCount);
    default:
      throw "UNKOWN TENANT ID";
  }
}

export function getCustomerFriendlyBrandName(tenantId) {
  switch (tenantId) {
    case "5435117c-d007-11ec-9d64-0242ac120002":
    case "0abe829d-3c29-4700-98dc-f3c709f85514":
    case "4f6c9611-5ca6-424b-b675-5abfc45a44d4":
    case "68ad6406-f094-411e-8b99-55d02e6cc2a5":
    case "70f1aeeb-f07f-4d62-b055-e77b0c461e62":
    case "a7ccee84-5b03-4a81-bbe2-8b618c0de24a":
    case "c9a894c1-8f63-45d4-ae41-bc216ea49392":
    case "d264d0ee-88a1-44a2-8134-8ebe85910bd8":
    case "f6b8108a-563a-4f80-b2ce-4d6f95929d48":
    case "1f29e1ea-536b-11ee-8c99-0242ac120002":
    case "de2251a1-b6da-45c8-813a-96153b878c51":
    case "4ce08156-dbfc-4e50-b92e-ee7458de457f":
    case "d4b2ed4c-9b6b-49b4-9c07-108168c3c0ac":
    case "f4f2e21e-7abc-4151-a1a3-70e89f91a28b":
    case "fb17253d-80b3-45fc-b31a-3407127c5fbd":
    case "ef6ff7d5-8881-44d2-8010-faee41262123":
    default:
      return "Vibe";
  }
}

export function getSupport(tenantId) {
  switch (tenantId) {
    case "5435117c-d007-11ec-9d64-0242ac120002":
      return SupportVibeO2O;
    case "0abe829d-3c29-4700-98dc-f3c709f85514":
      return SupportVibeChina;
    case "4f6c9611-5ca6-424b-b675-5abfc45a44d4":
      return SupportVibeRow;
    case "68ad6406-f094-411e-8b99-55d02e6cc2a5":
      return SupportWSAAudiology;
    case "70f1aeeb-f07f-4d62-b055-e77b0c461e62":
      return SupportComunicare;
    case "a7ccee84-5b03-4a81-bbe2-8b618c0de24a":
      return SupportBaodao;
    case "c9a894c1-8f63-45d4-ae41-bc216ea49392":
      return SupportChinaDistributors;
    case "d264d0ee-88a1-44a2-8134-8ebe85910bd8":
      return SupportFosun;
    case "f6b8108a-563a-4f80-b2ce-4d6f95929d48":
      return SupportVibeJP;
    case "1f29e1ea-536b-11ee-8c99-0242ac120002":
      return SupportVibeJPFirstFit;
    case "de2251a1-b6da-45c8-813a-96153b878c51":
      return SupportVibeEgypt;
    case "4ce08156-dbfc-4e50-b92e-ee7458de457f":
      return SupportSaudi;
    case "d4b2ed4c-9b6b-49b4-9c07-108168c3c0ac":
      return SupportTWHK;
    case "f4f2e21e-7abc-4151-a1a3-70e89f91a28b":
      return SupportSamsungHospital;
    case "fb17253d-80b3-45fc-b31a-3407127c5fbd":
      return SupportSGnanyang;
    case "ef6ff7d5-8881-44d2-8010-faee41262123":
      return SupportvibeIndia;
    default:
      throw "UNKOWN TENANT ID";
  }
}

export function getTermsAndConditionsEN(tenantId) {
  return TermsVibeChinaEN;
}

export function getTermsAndConditionsZH(tenantId) {
  return TermsVibeChinaZH;
}

export function getPrivacyPoliciesEN(tenantId) {
  return PrivacyVibeChinaEN;
}

export function getPrivacyPoliciesZH(tenantId) {
  return PrivacyVibeChinaZH;
}

export default getOffboardingContent;
