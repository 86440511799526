import React, { Component } from 'react'
import Header from './Header'
import { MdError } from 'react-icons/md'
import { FormattedMessage } from 'react-intl';
import { RightTheme, LeftTheme } from './BackgroundTheme'
import { ai } from './_helper/TelemetryService';
import { IntlConsumer } from './IntlContext';

class NotFoundError extends Component {

    componentDidMount(){
        ai.trackPageView('NotFoundError');
    }
    
    render() {
        return (
            <div>
                <IntlConsumer>
                {({locale}) =>
                <div className={`container-fluid mt-3 ${locale == "ar" ? "arabic" : ""}`}>
                    <div className="row">
                            <LeftTheme />
                        <div className="text-center" id="content">
                            <div className="contentContainer">
                                <div className="iconStyle-2"> <MdError /></div>
                                <div id="txt_NotFoundError">
                                    <FormattedMessage id="app.error.notfoundintro"
                                        defaultMessage="No settings were found."
                                        description="upper error message" />
                                </div>
                                <div id="txt_NotFoundError">
                                    <FormattedMessage id="app.error.notfounddetail"
                                        defaultMessage="Please make sure that you access the correct link that was provided to you."
                                        description="lower error message" />
                                </div>

                            </div>
                        </div>
                            <RightTheme />
                    </div>
                </div>
                }
                </IntlConsumer>
            </div>
        )
    }
}

export default NotFoundError