import React, { Component } from 'react'

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="legalText text-justify legalContent">
                <div id="secA" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        A. 我们对个人信息隐私的承诺
                    </span>
                    保护个人信息的安全和隐私对我们来说至关重要。我们根据相关的个人信息安全保护保护和数据安全法律开展业务。我们制定本隐私政策是为了表明我们对每个人的信息保护及隐私权的承诺。本隐私政策概述了我们如何处理您提供的信息，这些信息可以直接或间接地识别您，或者（在您是医疗服务提供者、听力学家或类似人员或销售人员的情况下）这些信息可以直接或间接地识别您的客户、患者或最终用户（统称为“个人信息”）。 
                    <div className="mt-1" />
                    我们的全球数据隐私合规计划还包括实施明确的授权和许可、欧盟标准合同条款、示范合同/转让协议、隐私原则、隐私声明、隐私政策、在特定国家的登记和备案。本隐私政策涵盖了网站 www.sivantos.com和所有其他适用的 Sivantos 网站（分别称为“网站”并统称为“网站”）以及 Sivantos 提供的软件应用程序（“应用程序”）和 Sivantos 通过网站提供的服务（“服务”）。
                </div>

                <div id="secB" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    B.	本隐私政策何时适用？ 
                    </span>
                    本隐私政策适用于您向我们提供的或源自下列个人信息的个人信息（例如姓名、地址、电话号码或电子邮件地址）。
                    <div className="mt-1" />
                    如果本隐私政策的英语和非英语版本之间有任何不一致之处（当根据本地要求进行翻译时），在适用法律允许的范围内，应以英语版本的本隐私政策为准。
                </div>

                <div id="secC" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        C.	数据控制者
                    </span>
                    网站的数据控制者是 Sivantos。如果在我们的网站上或在您使用我们的任何应用程序或服务时出现注册表或同意书，数据控制者可能会有所不同，可能是 Sivantos 的一家附属公司或子公司（统称为 “Sivantos”）或您自己（取决于数据的实际提供情况或数据收集的目的），并且可能在适用的注册表或同意书上显示。如需查询，您可通过 dpo@sivantos.com 与我们的数据保护官联系。
                </div>

                <div id="secD" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    D.	为何需要提供个人信息?
                    </span>
                    作为一般原则，您在本协议项下的任何同意和对任何个人信息的提供完全是自愿的，您可以根据本隐私政策的规定随时进行撤销。对于可用于直接或间接识别您的客户、患者或最终用户的信息，您保证并确认您获得这些人员的明确同意，根据本隐私政策的条款和您同意的关于个人信息披露的任何使用或许可条款，向我们提供他们的个人信息。
                    <div className="mt-1" />
                    除非您自愿允许（例如通过注册、调查等方式），向我们作出同意，或除非保护这些个人信息适用的法律法规另行许可，否则我们不会通过我们的网站和应用程序收集个人信息。
                </div>

                <div id="secE" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        E.	个人信息的用途是什么？
                    </span>
                    我们只会按照本隐私政策、您的注册表、同意书或相关的使用或许可条款的规定处理个人信息。更多信息可在下面的“使用目的”一节中获取。
                </div>


                <div id="secF" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    F.	处理个人信息的持续时间。
                    </span>
                    在我们按照本隐私政策或法律允许或合同规定处理并使用个人信息时，我们将 (i) 仅在本隐私政策规定、法律允许或合同规定实现目的所需的时间内或 (ii) 在您反对我们继续使用此等个人信息以前（在我们拥有合法权益使用这些个人信息的情况下），或 (iii) 在您撤销您的同意以前（在您已同意我们使用此等个人数据的情况下），存储这些个人信息。但是，如果我们需要保留这些个人信息更长时间以遵守法律或法规，或我们需要这些个人信息以便对合法权利要求进行主张或抗辩，我们将保留这些个人信息，直到相关的保留期结束或直到该权利要求得到解决。
                </div>

                <div id="secG" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        G.	在何处处理个人信息？
                    </span>
                    作为全球公司集团的一部分，我们在欧洲经济区 (“EEA”) 内外都有附属公司和子公司。因此，每当我们为了本隐私政策规定的目的使用或处理个人信息时，我们可将这些个人信息转移到位于欧洲经济区以外国家（包括适用的法定数据保护级别无法与欧洲经济区内数据保护级别相比的国家）的附属公司和子公司。无论何时发生这类数据转移，都是根据基于标准合同条款（根据欧盟委员会第 87/2010/EC 号决定或任何将来的替代规定）的公司间协议，以合同方式规定这些个人信息应按照在欧洲经济区内适用的数据保护级别。
                    <div className="mt-1" />
                    我们可能为了本隐私政策规定的目的，使用第三方服务提供商来处理个人信息。这些第三方服务提供商可能不在您的国家。在任何情况下，我们将与他们签订协议，以便他们根据本隐私政策和适用的数据保护法律处理个人信息。
                </div>

                <div id="secI" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        H.	我有哪些权利
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    一般权利。
                    </span>
                    您可随时向我们索取我们正在处理的个人信息以及改正或删除这些个人信息的有关信息。不过，请注意，我们只有在没有保留个人信息的法定义务或一般权利的情况下，方可删除这些个人信息。请注意，如果您要求我们删除这些个人信息，您将无法继续使用相关的网站、应用程序、服务或要求 Sivantos 使用这些个人信息的任何相关的 Sivantos 服务。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    索取副本。
                    </span>
                    如果我们根据您的同意使用个人信息或与您订立合同，您可向我们索取您所提供的个人信息副本。在这种情况下，请通过 apps@sivantos.com 与我们联系，并说明您希望获得的个人信息和格式。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    被遗忘的权利/使用限制。
                    </span>
                    在以下任何一种情况下，您可要求我们限制对个人信息的任何处理：(i) 您通知我们，我们拥有的个人信息是不正确的（在这种情况下，我们可以继续保留此等个人信息以检查其准确性）,(ii) 我们没有处理这些个人信息的法律依据，或 (iii) 您反对我们根据以下 L 节规定的我们的合法权益处理此等个人信息。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        投诉的权利。
                    </span>
                    如果您有理由相信，我们没有根据本隐私政策的要求或适用的欧洲经济区数据保护法律处理个人信息，您可以选择向您居住的欧洲经济区国家的数据保护机构或适用的 Sivantos 实体所在国家或州的有关数据保护机构投诉。
                </div>

                <div id="secH" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        I.	进一步的限制和具体规定
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        儿童对本网站的使用。
                    </span>
                    本网站不适合 16 岁以下人员。如果您未满 16 岁，您不能注册或使用本网站。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        其他网站的链接。
                    </span>
                    本网站可能包含其他（指非 Sivantos 公司）网站的链接。Sivantos 对 Sivantos 以外网站的隐私惯例或内容不负有责任。因此，我们建议您仔细阅读此等其他网站的隐私声明。
                </div>


                <div id="secH" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    J.	使用技术措施，例如 Cookies 等。
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    自动收集的非个人信息
                    </span>
                    当您访问我们的网站或使用我们的应用程序或服务时，我们可能会自动地（即不是通过注册）收集非个人信息（例如所使用的互联网浏览器和操作系统的类型、来源网站的域名、访问次数、在网站上所花的平均时间、查看的网页）。我们可以使用此等数据并与附属公司和子公司共享，以改进网站、应用程序和/或服务的性能、内容或外观。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    “Cookies”——自动存储在您的电脑上的信息
                    </span>
                    当您使用我们的网站、应用程序和/或服务时，我们可能将一些数据以 “cookie” 的形式存储在您的电脑上，以便下次您使用我们的网站、应用程序和/或服务时自动识别您的电脑。Cookies 可以在很多方面帮助我们，例如，使我们能够调整我们的网站、应用程序和/或服务，以更好地匹配您的兴趣或存储您的密码，避免您每次都要重新输入。如果您不希望接收 cookies，请将您的互联网浏览器进行设置，以便从您的电脑硬盘驱动器中删除所有 cookies，阻止所有 cookies 或在存储 cookie 之前收到警告。有关我们如何使用 Cookies 以及如何选择退出的更多详情，请参见我们的 Cookie 政策，网址 www.sivantos.com/cookie-policy/。 
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    谷歌分析 (Google Analytics)
                    </span>
                    我们的网站使用谷歌分析——由 Google, Inc.（“谷歌”）提供的一种网络分析服务。谷歌分析使用“cookies”，它们是放置在您电脑上的文本文件，能帮助网站分析用户如何使用我们的网站。由 cookie 生成的关于您使用我们网站的信息通常会传输给谷歌并由谷歌存储在美国的服务器上。只有在特殊情况下，完整的 IP 地址才会被传输到美国的谷歌服务器，在那里它将被缩写。根据我们的要求，谷歌将使用这些信息来评估您使用我们网站的情况，为网站运营商编写关于我们网站活动的报告，并提供其他与网站活动和互联网使用相关的服务。由您的浏览器通过谷歌分析传输的 IP 地址将不会与其他谷歌数据相关联。您可以通过在浏览器上选择适当的设置来拒绝使用 cookies，但是请注意，如果这样做，您可能无法使用我们网站的全部功能。此外，您可以通过下载和安装可以从以下链接获得的浏览器插件，防止通过谷歌收集由 cookie 生成的关于您使用我们网站情况的数据（包括您的 IP 地址）以及通过谷歌处理这些数据：http://tools.google.com/dlpage/gaoptout.
                </div>

                <div id="secL" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        K.	安全
                    </span>
                    为了保护个人数据免于意外或非法破坏、遗失或更改，以及防止未经授权的披露或访问，我们采用技术及组织安全措施。
                </div>


                <div id="secM" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        L.	使用目的
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold">
                        在下列情况下，我们获准根据相关的数据保护法律处理个人信息。
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    a)	提供要求的产品或服务。
                    </span>
                    如果您向我们订购产品或服务，我们将只使用个人信息（例如姓名、（电子邮件）地址、电话号码、公司名称及地址、信用卡号码或银行详细资料）来处理您的订单或提供要求的产品或服务。这也可能包括您可能通过聊天功能、联系人表单、电子邮件或电话触发的对话数据。在本隐私政策中，“产品和服务”包括 Sivantos 助听器和相关服务、网站服务、供应品、竞赛、其他内容、非营销相关的通讯、教程、培训和活动。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    b)	沟通。
                    </span>
                    我们经常通过电子邮件与使用我们产品或相关服务的用户进行沟通，我们也可能通过电话沟通解决客户投诉或调查可疑的交易。我们可能使用您的电子邮件地址来确认您的开户，向您发送付款通知，向您发送关于我们产品和服务的变更信息，以及根据法律要求发送通知和其他披露信息。通常，用户不能选择退出这些与营销无关而仅仅是相关业务关系所需的沟通。 
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    c)	合规。
                    </span>
                    此外您确认，为了及时了解您在个人信息的处理或使用方面的选择，或了解您是否愿意接收营销资料，Sivantos 成员可保留并相互交换任何必要信息，以确保合规。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    d)	Sivantos 的合法权益。
                    </span>
                    以下 e) 至 g) 分款中的每一种方法均构成我们处理或使用个人信息的合法权益。如果您不同意我们采用下列方法收集信息，则您可反对我们处理或使用通过这些方法收集到的个人信息。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    e)	问卷和调查。
                    </span>
                    我们可能邀请您参与问卷和调查。这些问卷和调查一般设计为无需任何个人信息也能回答。但是，如果您在问卷或调查中输入个人信息，我们可能会使用此等个人信息来改进 Sivantos 的产品和服务。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    f)	创建匿名数据库。
                    </span>
                    我们可能会将根据本隐私声明提供的个人信息匿名，以创建匿名数据集，然后用于改进 Sivantos 的产品和服务。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    g)	为质量改进目的记录通话和聊天。
                    </span>
                    如有通话或聊天对话，我们可能（在通话过程中以及在记录开始前通知您后）记录此等通话或聊天对话，以改进我们的服务质量。
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    h)	反对的权利。
                    </span>
                    您可随时反对我们将个人信息用于上述目的。如果您这样做，我们将停止为上述目的使用您的个人信息并从系统中将其删除，除非我们获准将此等个人信息用于本隐私声明规定的其他目的，或我们确定并展示了令人信服的合法权益以继续处理您的个人信息（在强制性的当地法律要求的情况下，例如为税收目的等）。 
                    <div className="mt-1" />
                    <span className="txt-bold">
                    在下列情况下，我们将在获得您事先同意后，仅根据以下进一步的详细说明来使用个人信息。
                    </span>

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                    a.	关于 Sivantos 产品和服务的新闻。
                    </span>
                    我们可能使用您的姓名、电子邮件和邮政地址、电话号码或（如果您是医疗服务提供者、听力学家或类似人员或销售人员）您的客户、患者或最终用户的姓名、电子邮件和邮政地址、电话号码以及关于您的雇主的基本信息（名称和地址），以便让您或（如果您是医疗服务提供者、听力学家或类似人员或销售人员）您的客户、患者或最终用户了解最新产品公告、关于 Sivantos 产品和服务的其他信息（包括营销相关的通讯）、Sivantos 的活动，以及在我们的网站上显示相关内容。
               </div>

                <div id="secL" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        M.	问题和意见
                    </span>
                    我们会回应合理的要求来审查个人信息，并更正、修改或删除任何不准确的数据。请通过 apps@sivantos.com 与我们联系来提出此等要求。 
                    <div className="mt-1" />
                    如果您对我们处理个人信息的方式有意见，您可以联系您曾经接洽的人员、部门或办公室。您也可以通过 dpo@sivantos.com 与我们的数据隐私官联系，负责数据隐私的人员将在合理的时间内与您联系。 

                    <div className="mt-1" />
                    本隐私政策可能会不时更新和修改。请经常查看本网页以了解最新情况。您继续或随后访问或使用我们的网站、应用程序和/或服务将被视为您接受更改或修改的隐私政策。在访问或使用我们的网站、应用程序和/或服务时，您接受当时版本的隐私政策。如果您不同意隐私政策，请勿使用我们的网站、应用程序和/或服务。
                    <div className="mt-1"/>

                    <span className="txt-bold">Sivantos Pte. Ltd.</span>
                    <br/>
                    <span className="txt-bold">18 Tai Seng Street</span>
                    <br/>
                    <span className="txt-bold">Singapore</span>
                    <br/>
                    <span className="txt-bold">dpo@sivantos.com</span>
                </div>
            </div>
        )
    }
}
export default PrivacyPolicy