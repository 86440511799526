import React, { Component } from 'react'
import Header from './Header'
import { MdError } from 'react-icons/md'
import { RightTheme, LeftTheme } from './BackgroundTheme'
import { FormattedMessage } from 'react-intl';
import { ai } from './_helper/TelemetryService';
import { IntlConsumer } from './IntlContext';

class ServerError extends Component {

    componentDidMount() {
        ai.startPageViewTracking();
        ai.trackPageView('ServerError');
    }

    render() {
        return (
            <div>
                <IntlConsumer>
                    {({locale}) =>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <LeftTheme />
                        <div className={`text-center ${locale == "ar" ? "arabic" : ""}`} id="content">
                            <div className="contentContainer">
                                <div className="iconStyle-2"> <MdError /></div>
                                <div id="txt_ServerError">
                                    <FormattedMessage id="app.error.serverissue"
                                        defaultMessage="Error while loading your settings."
                                        description="upper error message" />
                                </div>
                                <div>
                                    <FormattedMessage id="app.config.error.tryagainlater"
                                        defaultMessage="Please try again later or contact support for assistance."
                                        description="third line in unrecoverable error popup" />
                                </div>
                            </div>
                        </div>
                        <RightTheme />
                    </div>
                </div>
                }
                </IntlConsumer>
            </div>
        )
    }
}

export default ServerError