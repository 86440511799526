import React, { Component } from 'react'

class TermsAndConditions extends Component {
    render() {
        return (
            <div className="legalText text-justify legalContent">
                <div id="scope">
                    <span className="txt-bold legalTextParHeader">
                    1.范围。
                    </span>
                    这些使用和许可协议的条款和条件（统称为“许可”）由您（“您”或“您的”）和 Sivantos (“SIVANTOS”) 订立。SIVANTOS 可以是 Sivantos Pte. Ltd. 的子公司或附属公司，这取决于许可的实际标的物以及提供和达成许可的地点。本许可规定了您使用应用程序、软件和文件的条款和条件。
                </div>

                <div id="doc" className="mt-1">
                    <span className="legalTextSubParHeader" style={{'textDecoration': 'underline'}}>
                    在本许可中： 
                    </span>
                </div>

                <div id="doc" className="mt-1">
                    <span className="txt-bold legalTextSubParHeader">
                    a)“应用程序”
                    </span>
                    指 SIVANTOS 向您提供的应用程序，包含软件。 
                </div>

                <div id="doc" className="mt-1">
                    <span className="txt-bold legalTextSubParHeader">
                    b)“文件”
                    </span>
                    指 SIVANTOS 向您提供的任何技术文件，描述应用程序和/或软件的特点和功能。 
                </div>

                <div id="doc" className="mt-1">
                    <span className="txt-bold legalTextSubParHeader">
                    c)“软件”
                    </span>
                    指通过软件应用程序或目标代码形式向您提供的 SIVANTOS 软件。
                </div>

                <div id="acceptance" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    2.接受。
                    </span>
                    通过在访问、下载或安装软件时点击“我接受”或类似按钮，您同意本许可将专门适用 SIVANTOS 的许可以及您对软件和文件的使用，除非您与 SIVANTOS 有单独适用的书面协议。如果您代表另一个人、公司或其他法律实体接受本许可，无论是作为医疗服务提供者、听力学家、雇员、承包商、分销商、转售商、代理人还是其他，您声明并保证您有充分的权限来约束他们。如果您不同意或无权同意本许可的条款，请勿下载、安装、复制、访问或使用软件或文件，也不要点击“我接受”或类似的按钮，并在适用的情况下，立即将软件和文件归还提供方。
                </div>

                <div id="grant" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        3.授予许可。
                    </span>
                    SIVANTOS 授予您个人、非排他性、不可转让、世界范围内、有限且可撤销的许可（不包括分许可权），以便 i) 安装和/或使用软件后，仅将其用于您的内部业务目的（如果您是医疗服务提供者、听力学家或类似人员）或用于与您从 SIVANTOS 购买助听器或相关服务有关的目的，以及 ii) 使用文件资料，以帮助您更好地使用软件。
                <div className="mt-1" />
                如果您下载了软件，您有权在任何数量的电脑上安装和使用该程序，但仅可制作一 (1) 份备份副本。
                <div className="mt-1" />
                本许可不授予获取软件未来升级、更新或补充的任何权利。但是，如果获得软件的升级、更新或补充，则在使用升级或更新后的软件时，仍需遵守本许可及其修订内容的规定，除非在对软件进行升级、更新或补充时规定了其他条款，在这种情况下适用这些条款。
                </div>

                <div id="restricions" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    4.许可限制。
                    </span>
                    您不应该，也不应该允许任何第三方：a) 逆向工程、反编译或反汇编软件或以其他方式将其简化为可读格式；b) 移除或隐藏软件或文件中的任何产品标识、专有知识产权或其他通知；c) 将软件或文件许可全部或部分让与或以其他方式转让给另一方；或 d) 修改、改编或创建软件或文件的衍生作品。
                </div>

                <div id="ip" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    5.知识产权。
                    </span>
                    软件和文件是许可给您使用的，而不是销售给您的。它受到全球知识产权法律和条约的保护，并包含商业秘密，其中 SIVANTOS 及其许可人保留未明确授予您的所有权利。SIVANTOS 或其许可人的任何商标、服务标志、标识或商标名称的权利、所有权或权益均不授予您。
                </div>

                <div id="limitedwarranty" className="mt-1 txt-bold">
                    <span className="txt-bold legalTextParHeader">
                    6.有限保证。
                    </span>
                    SIVANTOS 对软件或文件的任何权利或质量缺陷不承担任何保证。这些免责声明不适用于欺诈性隐瞒这些缺陷的情况。软件描述不应构成对任何特点的保证，即使它们提到 DIN 和/或其他标准。SIVANTOS 不保证您对软件的使用无错误或不间断。如果您或任何第三方滥用或修改软件或将软件用于非本许可允许的任何目的，则 SIVANTOS 的保证义务将被取消。在相关法律允许的范围内，这是您唯一的和排他性的保证和救济。SIVANTOS 明确否认所有默示的保证，包括对适销性、可接受的质量、特定用途的适用性和无侵权的保证。 
                </div>

                <div id="limitedLiablity" className="mt-1 txt-bold">
                    <span className="txt-bold legalTextParHeader">
                    7.责任限制。
                    </span>
                    SIVANTOS 及其许可人不承担以下责任:a) 任何间接、后果性、附带性、惩戒性或特殊损害赔偿；b) 信息丢失或损坏；c) 实际或预期收入、利润、商誉或储蓄的损失；d) 替代品和/或服务的采购以及 e) 业务中断。  
                <div className="mt-1"></div>
                上述限制和例外情况不适用于因 SIVANTOS 的疏忽、故意的不当行为或欺诈造成的死亡或人身伤害责任，以及根据相关法律不能排除的 SIVANTOS 的任何其他责任。 
                </div>

                <div id="termination" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    8.终止。
                    </span>
                    如果您违反本许可，SIVANTOS 可随时终止本许可。在本许可终止后，您应立即停止使用软件和文件，并在适用的情况下，归还或销毁软件和文件的所有副本。
                </div>

                <div id="ossoftware" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    9.开源软件。
                    </span>
                    软件是使用或以其他方式集成以下开源软件开发的：-
                    <ul>
                        <li>
                            sdfk
                        </li>
                    </ul>
                    开放源码许可的条款适用于软件，如果与本许可有任何不一致之处，应以开放源码许可的条款为准。 
                </div>

                <div id="exportcontrol" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    10.出口管制。
                    </span>
                    您应遵守所有适用的国家和国际(再)出口管制条例。在任何情况下，用户应遵守德意志联邦共和国、欧盟和美国的(再)出口管制条例。
                </div>

                <div id="dataprivacy" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    11.个人信息隐私。
                    </span>
                    您对您选择使用软件管理和/或存储的个人信息负有全部责任，并同意遵守所有相关信息隐私法律。SIVANTOS 只负责从您那里（或通过您授权的人员，比如您的医疗服务提供者或听力学家）明确获得的个人信息，以便您使用软件和/或 SIVANTOS 向您提供助听器和相关服务。如果您是医疗服务提供者、听力学家或类似人员，您应明确保证并声明第三方（比如您的客户和患者）的个人信息。在这种情况下，SIVANTOS 使用这些个人信息时，应遵照其现行的隐私政策，而您需要接受该政策或(如果您是医疗服务提供者、听力学家或类似人员)，才可继续进行。 
                </div>

                <div id="general" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    12.通则。
                    </span>
                    本许可应根据新加坡法律进行解释。不适用 1990 年 4 月 11 日《联合国国际货物销售合同公约》。根据《合同（第三方权利）法》（第 53B 章）或其他规定，除 SIVANTOS 的附属公司和子公司外，非本许可一方的任何人员不得执行本许可。

                    <div className="mt-1" />
                    如果本许可的任何一节或其部分被确定为无效或不可执行，则本许可的其余部分仍具有完全的效力。除非由各方经授权的代表签署书面修正案，否则不得更改本许可。如果本许可的英语和非英语版本之间有争议（当根据本地要求进行翻译时），在相关法律允许的范围内，应以英语版本的本许可为准。本许可代表了 SIVANTOS 和您之间关于软件和文件的完整约定和协议。它取代 SIVANTOS 和您之间以前的任何沟通、声明或协议，并且优先于双方之间的任何报价、采购订单、确认或类似沟通的任何冲突或附加条款。
                </div>

                <div id="infogathering" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                    13. 信息收集
                    </span>
                    您了解并接受软件配备了第三方组件，这些组件使软件能够通过第三方提供者收集技术信息，并将该技术信息报告给 SIVANTOS。您可以在用户首选项中禁用这种功能。
                    <div className="mt-1" />
                    所收集的技术信息是关于您的 IT 安装的配置信息，包括但不限于安装软件的电脑配置、软件的使用以及电脑所在的国家。
                    <div className="mt-1" />
                    SIVANTOS 不会收集任何个人信息作为信息收集过程的一部分。SIVANTOS 使用收集的技术信息来改进软件。
                </div>

            </div>
        )
    }
}
export default TermsAndConditions