import React, { Component } from "react";
import { withRouter } from "react-router";

class ProgressBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const noOfSteps = 3 + this.props.deviceCount;
        const currentStep = this.props.currentStep;

        const stepItems = [];


        for (let i = 0; i < noOfSteps; i++) {
            let style = "progressBarItem ";

            if (i < currentStep) {
                style += "progressBarItemActive";
            }
            else {
                style += "progressBarItemInactive";
            }
            stepItems.push(<div key={"progressBar" + i} className={style} />);
        }


        return (
            <div className="progressBar">
                <div className="progressBarContainer">
                    {stepItems}
                </div>
                <div className="text-center">
                    {currentStep}/{noOfSteps}
                </div>
            </div>
        );
    }
}

export default withRouter(ProgressBar);
