import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Onboarding from "./Onboarding";
import SoundCheck from "./SoundCheck";
import Configuration from "./Configuration";
import Offboarding from "./Offboarding";
import NotFoundError from "./NotFoundError";
import ServerError from "./ServerError";
import Help from "./Help";
import Feedback from "./Feedback";

function requireId(replace){
  if( typeof this.props.location.state== 'undefined'){
    replace({
      pathname: '/login'
    })
}
}

const reload = () => {
  console.log('Trying to reach file');
  window.location.reload();
}

export const Routes = () => (
  <div>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route  path="/onboarding" component={Onboarding} onEnter={requireId} />
      <Route  path="/soundcheck" component={SoundCheck} />
      <Route  path="/configuration" component={Configuration} />
      <Route  path="/offboarding" component={Offboarding} />
      <Route path="/NotFoundError" component={NotFoundError} />
      <Route path="/ServerError" component={ServerError} />
      <Route path="/Help" component={Help} />
      <Route path="/Feedback" component={Feedback} />
      <Route path="/ac2QB3mK5n.txt" onEnter={reload}/>
    </Switch>
  </div>
);
