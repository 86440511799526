import { Switch } from "react-router";

const initialState = {
    name: "test1",
    tenantId: "1234"
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'Customer_Payload':
            return Object.assign({}, state, {
                name: action.data.customerName,
                tenantId: action.data.tenantId,
                hiUriLeft: action.data.hiUriLeft,
                hiUriRight: action.data.hiUriRight,
                hiNameLeft: action.data.hiNameLeft,
                hiNameRight: action.data.hiNameRight,
                brand:action.brand,
                custId:action.custId,
                deviceCount:action.deviceCount
            })
        case 'Brand_Name': {
            return Object.assign({}, state, {
                ...state,
                brand: action.brand
            })
        }
        default:
            return state

    }


}
