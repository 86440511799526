import React, { Component } from "react";
import { connect } from 'react-redux';
import { LeftTheme, RightTheme } from "./BackgroundTheme";
import { IntlConsumer } from './IntlContext';
import ProgressBar from "./ProgressBar";
import { getOffboardingContent } from './_helper/BrandedContent';
import { ai } from "./_helper/TelemetryService";

class Offboarding extends Component {
  componentDidMount() {
    ai.startPageViewTracking();
    ai.trackPageView("Offboarding");
  }

  componentWillUnmount() {
    ai.stopPageViewTracking("Offboarding");
  }

  render() {
    return (
      <div>
        <ProgressBar deviceCount={this.props.deviceCount} currentStep={this.props.deviceCount + 3} />
        <IntlConsumer>
            {({locale}) =>
        <div className='container-fluid'>
          <div className="row">
            <LeftTheme />
            <div id='content'>
              <div
                className={`text-center contentContainer  ${locale == "ar" ? "arabic" : ""}`}
                id="offboardingContainer"
              >
              {getOffboardingContent(this.props.tenant, this.props.deviceCount)}
              </div>
            </div>
            <RightTheme />
          </div>
        </div>
        }
        </IntlConsumer>
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    deviceCount:state.deviceCount,
    tenant:state.tenantId
  };
}

export default connect(mapStateToProps)(Offboarding);

