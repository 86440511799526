import React, { Component } from "react";
import { FaInfo } from "react-icons/fa";
import ProgressBar from "./ProgressBar";
import { RightTheme, LeftTheme } from "./BackgroundTheme";
import { FormattedMessage } from "react-intl";
import { ai } from "./_helper/TelemetryService";
import RouterCheck from './RouterCheck'
import {connect} from 'react-redux';
import { IntlConsumer } from "./IntlContext";

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    RouterCheck(this.props);
    ai.startPageViewTracking();
    ai.trackPageView("Onboarding");
  }

  componentWillUnmount() {
    ai.stopPageViewTracking("Onboarding");
  }

  handleSubmit(event) {
    ai.trackEvent("Onboarding continue", {
      id: this.props.custId
    });
    event.preventDefault();
    this.props.history.push({
      pathname: "/soundcheck",
      search:'id='+this.props.custId,
      state: {
        cust: this.props.location.state.cust
      }
    });
  }

  render() {
    return (
      <div>
        {this.props.location.state?
        <React.Fragment> 
        <ProgressBar deviceCount={this.props.deviceCount} currentStep="1" />
        <IntlConsumer>
              {({ locale }) => 
          <div className='container-fluid'>
          <div className='row'>
            <LeftTheme />
            <div id="content" className={` ${locale == "ar" ? "arabic" : ""}`}>
              <div className="text-center mt-2">
                <FaInfo id="icon-info" className="iconStyle-2" />
              </div>
              <div className="onboardingContainer">
                <div className="onboardingList mt-3" id="txt_Onboarding">
                  <ul>
                    <li>
                      <FormattedMessage
                        id="app.onboarding.readInstructionsCarefully"
                        defaultMessage="Place the {deviceCount, plural, one {hearing aid} other {hearing aids}} in front of you and follow all instructions carefully"
                        description="First line of instruction on onboarding screen"
                        values={{
                          deviceCount: this.props.deviceCount
                        }}
                      />
                    </li>

                    <li>
                      <FormattedMessage
                        id="app.onboarding.quietEnvironment"
                        defaultMessage="Go to a quiet environment"
                        description="Second line of instruction on onboarding screen"
                      />
                    </li>

                    <li>
                      <FormattedMessage
                        id="app.onboarding.properlyAssembled"
                        defaultMessage="Make sure your hearing aids are properly assembled and a working battery is inserted"
                        description="Third line of instruction on onboarding screen"
                      />
                    </li>

                    <li>
                      <FormattedMessage
                        id="app.onboarding.unplugHeadphones"
                        defaultMessage="Unplug wired headphones and disconnect bluetooth audio devices"
                        description="Fourth line of instruction on onboarding screen"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  id="btnContinue"
                  className="largeButton"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage
                    id="app.general.continue"
                    defaultMessage="Continue"
                    description="Text of continue button on multiple screens"
                  />
                </button>
              </div>
            </div>
            <RightTheme />
          </div>
        </div>
        }</IntlConsumer>
        </React.Fragment>:null}
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    deviceCount:state.deviceCount,
    custId: state.custId
  };
}

export default connect(mapStateToProps)(Onboarding);

