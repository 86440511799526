import React, { Component } from 'react';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import Hi from './HearingInstrument'
import Loader from 'react-loader-spinner'
import { FaQuestion, FaExclamationTriangle } from 'react-icons/fa'
import { RightTheme, LeftTheme } from './BackgroundTheme'
import { FormattedMessage } from 'react-intl';
import ProgressBar from "./ProgressBar";
import { ai } from './_helper/TelemetryService';
import RouterCheck from './RouterCheck'
import { IntlConsumer } from "./IntlContext";

import batteryDoorOpenCloseITE_LEFT from '../Assets/Images/batterydoor/openclose-ite_L.gif';
import batteryDoorOpenCloseITE_RIGHT from '../Assets/Images/batterydoor/openclose-ite_R.gif';

import batteryDoorOpenCloseBTE_LEFT from '../Assets/Images/batterydoor/openclose-bte_L.gif';
import batteryDoorOpenCloseBTE_RIGHT from '../Assets/Images/batterydoor/openclose-bte_R.gif';

import batteryDoorOpenBTE_LEFT from '../Assets/Images/batterydoor/open-bte_L.gif';
import batteryDoorOpenITE_LEFT from '../Assets/Images/batterydoor/open-ite_L.gif';

import batteryDoorCloseBTE_LEFT from '../Assets/Images/batterydoor/close-bte_L.gif';
import batteryDoorCloseITE_LEFT from '../Assets/Images/batterydoor/close-ite_L.gif';

import openVibeGo_LEFT from '../Assets/Images/batterycase/open-vibeGo_L.gif';
import dropVibeGo_LEFT from '../Assets/Images/batterycase/drop-vibeGo_L.gif';
import openVibeGo_RIGHT from '../Assets/Images/batterycase/open-vibeGo_R.gif';
import openVibeGoBoth_LEFT from '../Assets/Images/batterycase/open-vibeGo-both_L.gif';

import openVibeComplete_LEFT from '../Assets/Images/batterycase/open-vibeComplete_L.gif';
import dropVibeComplete_LEFT from '../Assets/Images/batterycase/drop-vibeComplete_L.gif';
import openVibeComplete_RIGHT from '../Assets/Images/batterycase/open-vibeComplete_R.gif';
import openVibeCompleteBoth_LEFT from '../Assets/Images/batterycase/open-vibeComplete-both_L.gif';

import openVibeMax_LEFT from '../Assets/Images/batterycase/open-vibeMax_L.gif';
import dropVibeMax_LEFT from '../Assets/Images/batterycase/drop-vibeMax_L.gif';
import openVibeMax_RIGHT from '../Assets/Images/batterycase/open-vibeMax_R.gif';
import openVibeMaxBoth_LEFT from '../Assets/Images/batterycase/open-vibeMax-both_L.gif';

import {connect} from 'react-redux';

class Configuration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            intitialModalShow: true, label: "", configureModalShow: false,
            configured: false, hiUri: "", loadingModalShow: false, id: "",
            sideEnum: "", pairingAddress: "", unpowerDeviceModalShow: false,
            unrecoverableErrorModalShow: false, timeoutErrorModalShow: false,
            error: "", toneLoadText: ""
        }
        this.initialModalClose = this.initialModalClose.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.handleConfigure = this.handleConfigure.bind(this);
        this.handleConfigureNo = this.handleConfigureNo.bind(this);
        this.handleConfigureYes = this.handleConfigureYes.bind(this);
        this.unpowerDeviceModalClose = this.unpowerDeviceModalClose.bind(this);
        this.powerDeviceModalClose = this.powerDeviceModalClose.bind(this);
        this.navigateToOffboarding = this.navigateToOffboarding.bind(this);
        this.URLBuilder = this.URLBuilder.bind(this);
        this.handleTimeout = this.handleTimeout.bind(this);
        this.timeoutModalClose = this.timeoutModalClose.bind(this);
        this.getSetup = this.getSetup.bind(this);
        this.getStep = this.getStep.bind(this);
        this.getProgressBarStep = this.getProgressBarStep.bind(this);
        this.audioElm = React.createRef();
    }

    componentDidMount() {
        RouterCheck(this.props);
        ai.startPageViewTracking();
        var pairingAddress = Math.floor(Math.random() * (15 - 0 + 1)) + 0;
        ai.traceInfo("pairingAddress: " + pairingAddress);

         if (this.props.location.state) {
            if (this.props.hiUriLeft != null) {
                this.setState({
                    label: "LEFT",
                    hiUri: this.props.hiUriLeft,
                    id: this.props.custId,
                    hiName: this.props.hiNameLeft,
                    sideEnum: 1,
                    pairingAddress: pairingAddress
                })
            }
            else {
                this.setState({                   
                    label: "RIGHT",
                    hiUri: this.props.hiUriRight,
                    id: this.props.custId,
                    hiName: this.props.hiNameRight,
                    sideEnum: 2,
                    pairingAddress: pairingAddress
                })
            }


            ai.trackPageView('Config ' + this.state.label);
        }
    }
    

    componentWillUnmount() {
        ai.stopPageViewTracking('Configuration ' + this.getSetup());
    }

    initialModalClose() {
        ai.trackEvent("Close Prep Dialog " + this.getStep(), { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.setState({ intitialModalShow: false })
    }

    unpowerDeviceModalClose() {
        ai.trackEvent("Close Unpower Dialog", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.setState({ label: "RIGHT", modalShow: true, intitialModalShow: true, hiUri: this.props.hiUriRight, hiName: this.props.hiNameRight, configured: false, sideEnum: 2, unpowerDeviceModalShow: false })
    }

    powerDeviceModalClose() {
        ai.trackEvent("Close Power Dialog", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.setState({ powerDeviceModalShow: false })
        this.navigateToOffboarding();
    }

    handleConfigure() {
        this.setState({ loadingModalShow: true });
        var timeout = setTimeout(this.handleTimeout, 25000);
        var toneURL = this.URLBuilder();

        ai.traceInfo("start fetching tones", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        ai.trackEvent("Configure", { 'id': this.props.custId, 'side': this.state.label, 'toneUrl': toneURL, 'setup': this.getSetup() });

        this.audioElm.current = new Audio(toneURL);
        this.audioElm.current.load();
        // Added line below to play the audio element due to a bug from iOS 17.4 onwards [https://forums.developer.apple.com/forums/thread/748001], if alread resolved, please remove the line below
        this.audioElm.current.play();

        this.audioElm.current.onended = () => {
            ai.trackEvent("Tones ended", { 'id': this.props.custId, 'side': this.state.label, 'toneUrl': toneURL, 'setup': this.getSetup() });
            ai.traceInfo("tones onenended", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
            this.setState({ loadingModalShow: false, configureModalShow: true });

        };

        this.audioElm.current.onerror = (error) => {
            var errorDescription = Object.keys(Object.getPrototypeOf(error.currentTarget.error)).find(key => error.currentTarget.error[key] === error.currentTarget.error.code);
            ai.traceError("Error while retrieving tones", errorDescription, { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
            clearTimeout(timeout);
            this.setState({ loadingModalShow: false, unrecoverableErrorModalShow: true, error: error });
        }

        this.audioElm.current.onstalled = () => {
            ai.traceInfo("tones stalled", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }

        this.audioElm.current.onloadstart = () => {
            this.setState({
                toneLoadText: <FormattedMessage id="app.config.toneLoadText_first"
                    defaultMessage="Downloading your personalised settings"
                    description="Text shown to user while tones are downloaded" />
            })
            ai.traceInfo("tones loadStart", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }

        this.audioElm.current.ondurationchange = () => {
            this.setState({
                toneLoadText: <FormattedMessage id="app.config.toneLoadText_first"
                    defaultMessage="Downloading your personalised settings"
                    description="Text shown to user while tones are downloaded" />
            })
            ai.traceInfo("tones durationChange", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }

        this.audioElm.current.onloadedmetadata = () => {
            this.setState({
                toneLoadText: <FormattedMessage id="app.config.toneLoadText_second"
                    defaultMessage="Configuring your hearing aid"
                    description="Text shown to user while tones are downloaded" />
            })
            ai.traceInfo("tones loadedMetadata", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }

        this.audioElm.current.onloadeddata = () => {
            this.setState({
                toneLoadText: <FormattedMessage id="app.config.toneLoadText_second"
                    defaultMessage="Configuring your hearing aid"
                    description="Text shown to user while tones are downloaded" />
            })
            ai.traceInfo("tones loadeddata", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }

        this.audioElm.current.onprogress = () => {
            ai.traceInfo("tones progress", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }

        this.audioElm.current.playing = () => {
            ai.traceInfo("tones playing", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }

        this.audioElm.current.pause = () => {
            ai.traceInfo("tones pause", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }


        this.audioElm.current.oncanplay = () => {
            this.setState({
                toneLoadText: <FormattedMessage id="app.config.toneLoadText_second"
                    defaultMessage="Configuring your hearing aid"
                    description="Text shown to user while tones are downloaded" />
            })
            ai.traceInfo("tones canplay", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
            ai.trackEvent("Tones playing", { 'id': this.props.custId, 'side': this.state.label, 'toneUrl': toneURL, 'setup': this.getSetup() });
            this.audioElm.current.play();
            clearTimeout(timeout);
        }

        this.audioElm.current.oncanplaythrough = () => {
            this.setState({
                toneLoadText: <FormattedMessage id="app.config.toneLoadText_second"
                    defaultMessage="Configuring your hearing aid"
                    description="Text shown to user while tones are downloaded" />
            })
            ai.traceInfo("tones canplaythrough", { 'url': toneURL, 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }
    }

    handleTimeout() {
        this.audioElm.current = null;
        ai.traceError("Timeout while retrieving tones", "error", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.setState({ loadingModalShow: false, timeoutErrorModalShow: true });
    }

    timeoutModalClose() {
        ai.trackEvent("Close timeout popup", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.setState({ timeoutErrorModalShow: false }, () => this.handleConfigure());
    }

    getSetup() {
        if (this.props.location.state) {
            if (this.props.hiUriLeft != null && this.props.hiUriRight != null)
                return "Binaural";
            else
                return "Monaural";
        }
    }

    getStep() {
        if (this.getSetup() === "Monaural")
            return "1/1";
        else {
            return this.state.sideEnum + "/2";
        }
    }

    getProgressBarStep() {
        let progressBarStep = 0;

        if (this.getSetup() === "Monaural")
            progressBarStep = 3;
        else {
            if (this.state.sideEnum === 1)
                progressBarStep = 3;
            else
                progressBarStep = 4;
        }
        return progressBarStep;
    }

    handleContinue(event) {
        event.preventDefault();

        
        if (this.props.hiUriLeft != null && this.props.hiUriRight != null && this.state.label.toLowerCase() === 'left') {
            //switch from left to right, show turn off popup and then continue to right side
            this.setState({ unpowerDeviceModalShow: true })
            ai.trackEvent(this.getSetup() + " Config continue to right", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }
        else if (this.props.hiUriLeft != null && this.props.hiUriRight != null && this.state.label.toLowerCase() === 'right') {
            //binaural case, right side done. show turn on popup for left side and continue to next screen
            this.setState({ powerDeviceModalShow: true })
            ai.trackEvent(this.getSetup() + " Config continue to power on popup", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }
        else if (this.props.hiUriLeft == null && this.props.hiUriRight != null && this.state.label.toLowerCase() === 'right') {
            //monaural case, right side done, do not show turn on popup for left side and continue to next screen
            this.navigateToOffboarding();
            ai.trackEvent(this.getSetup() + " Config continue to offboarding", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }
        else if (this.props.hiUriLeft != null && this.props.hiUriRight == null && this.state.label.toLowerCase() === 'left') {
            //monaural case, left side done, do not show turn on popup for left side and continue to next screen
            this.navigateToOffboarding();
            ai.trackEvent(this.getSetup() + " Config continue to offboarding", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        }
    }

    handleConfigureNo() {
        ai.trackEvent(this.getSetup() + " confirmation not heard " + this.getStep(), { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.setState({ configureModalShow: false, intitialModalShow: true, configured: false, })
    }

    handleConfigureYes() {
        ai.trackEvent(this.getSetup() + " confirmation heard " + this.getStep(), { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.setState({ configureModalShow: false, configured: true })
    }

    navigateToOffboarding() {
        ai.trackEvent("Go to offboarding", { 'id': this.props.custId, 'side': this.state.label, 'setup': this.getSetup() });
        this.props.history.push({
            pathname: "/offboarding",
            search: 'id=' + this.props.custId,
            state: {
                cust: this.props.location.state.cust,
            }
        });
    }

    URLBuilder() {
        var url = process.env.REACT_APP_USECASEAPI + '/tones/?id=' + this.props.custId + '&pairAddress=' + this.state.pairingAddress + '&earSide=' + this.state.sideEnum;
        return url;
    }

    getOpenCloseBatteryDoorImage(uri, side) {
        var type = this.getDeviceType(uri);
        var isLeftSide = side.toLowerCase() === 'left';
        switch(type) {
            case "Vibe Go":
                return isLeftSide ? openVibeGo_LEFT : openVibeGo_RIGHT;
            case "Vibe Complete":
                return isLeftSide ? openVibeComplete_LEFT : openVibeComplete_RIGHT;
            case "Vibe Max":
            case "Vibe Ultra":
                return isLeftSide ? openVibeMax_LEFT : openVibeMax_RIGHT;
            case "BTE":
                return isLeftSide ? batteryDoorOpenCloseBTE_LEFT : batteryDoorOpenCloseBTE_RIGHT;
            default:
                return isLeftSide ? batteryDoorOpenCloseITE_LEFT : batteryDoorOpenCloseITE_RIGHT;
        }
    }

    getOpenBatteryDoorImage(uri) {
        var type = this.getDeviceType(uri);
        switch(type) {
            case "Vibe Go":
                return dropVibeGo_LEFT;
            case "Vibe Complete":
                return dropVibeComplete_LEFT;
            case "Vibe Max":
            case "Vibe Ultra":
                return dropVibeMax_LEFT;
            case "BTE":
                return batteryDoorOpenBTE_LEFT;
            default:
                return batteryDoorOpenITE_LEFT;
        }
    }

    getCloseBatteryDoorImage() {
        var type = this.getDeviceType(this.props.hiUriLeft);
        switch(type) {
            case "Vibe Go":
                return openVibeGoBoth_LEFT;
            case "Vibe Complete":
                return openVibeCompleteBoth_LEFT;
            case "Vibe Max":
            case "Vibe Ultra":
                return openVibeMaxBoth_LEFT;
            case "BTE":
                return batteryDoorCloseBTE_LEFT;
            default:
                return batteryDoorCloseITE_LEFT;
        }
    }

    getDeviceType(uri) {
        switch (uri) {
            case 'SIV1:0/18/GO/1':
                return "Vibe Go"
            case 'SIV1:0/18/72022521809666V/1':
            case 'SIV1:0/18/72022521809666C/1':
                return "Vibe Complete"
            case 'SIV1:0/18/12348358706/1':
                return "Vibe Max"
            case 'SIV1:0/18/12348395570/1':
                return "Vibe Ultra"
            case 'SIV1:0/18/262064064/1':
            case 'SIV1:0/18/242065032/1':
            case 'SIV1:0/18/242064034/1':
            case 'SIV1:0/18/242064033/1':
            case 'SIV1:0/18/222066064/1':
            case 'SIV1:0/18/242065033/1':
                return "BTE";
            case 'SIV1:0/18/20054D064/1':
            
            case 'SIV1:0/18/20034D065/1':
                return "ITE";
            default: return "";
        }
    }

    isRechargeableDevice(type) {
        const devices = ['Vibe Go', 'Vibe Complete', 'Vibe Max', 'Vibe Ultra']
        return devices.includes(type);
    }

    getPairingTextKey(uri) {
        var type = this.getDeviceType(uri);
        if (this.isRechargeableDevice(type))
            return "app.config.prepdevicepopup.putback"
        return "app.config.prepdevicepopup.openclose"
    }

    getPairingText(uri) {
        var type = this.getDeviceType(uri);
        if (this.isRechargeableDevice(type))
            return "Put both hearing aids back in the charging box and close it. Open the charging box of {device} and take the {side} ear out."
        return "Open and close the battery door of your {device} for the {side} ear as shown in the image above."
    }

    getPowerResetTextKey(uri, powerOn) {
        var type = this.getDeviceType(uri);
        if (this.isRechargeableDevice(type))
            return powerOn 
            ? "app.config.poweroffpopup.turnon.rechargeable"
            : "app.config.poweroffpopup.turnoff.rechargeable"
        return powerOn
        ? "app.config.poweroffpopup.turnon"
        : "app.config.poweroffpopup.turnoff"
    }

    getPowerResetText(uri, powerOn) {
        var type = this.getDeviceType(uri);
        if (this.isRechargeableDevice(type))
            return powerOn 
            ? "Please turn on your {side} hearing aid by opening the charging box and taking it out and put it back into your ear." 
            : "Please turn off your {side} hearing aid ({device}) by putting the hearing aid back in the charging box and close it."
        return powerOn
        ? "Please turn on your {side} hearing aid by closing the battery door and put it back into your ear."
        : "Please turn off your {side} hearing aid ({device}) by opening the battery door."
    }

    render() {
        return (
            <div>
            <IntlConsumer>
                {({locale}) =>
              <div>
                {this.props.location.state ? <React.Fragment>
                    <ProgressBar deviceCount={this.props.deviceCount} currentStep={this.getProgressBarStep()} />

                    <div id="config" className='container-fluid'>


                        <div className="row">
                            <LeftTheme />

                            <div id="content" className={`${locale == "ar" ? "arabic" : ""}`}>
                                <h3 className="text-center mt-3">
                                    <p id="earSide" style={{ fontSize: '2.25rem', textTransform: "uppercase" }} className={this.state.sideEnum === 1 ? 'leftText' : 'rightText'}>
                                        {this.state.sideEnum === 1 ?
                                            <FormattedMessage capitalize id="app.general.left"
                                                defaultMessage="left"
                                                description="left" /> :
                                            <FormattedMessage id="app.general.right"
                                                defaultMessage="right"
                                                description="right" />}
                                    </p>
                                </h3>
                                <div className="text-center">
                                     <Hi className="hiImage" hi={this.state.hiUri} identifier={this.state.label} 
                                    name={this.state.hiName} configured={this.state.configured} tenantId={this.props.tenantId}/>
                                    <button className={'smallButton ' + (this.state.configured ? 'btnNo' : null)} type="button" id="configureButton" onClick={this.handleConfigure}>
                                        {this.state.configured ?
                                            <FormattedMessage id="app.general.reconfigure"
                                                defaultMessage="Reconfigure"
                                                description="Button caption for Reconfigure buttons" />
                                            :
                                            <FormattedMessage id="app.general.configure"
                                                defaultMessage="Configure"
                                                description="Button caption for Configure buttons" />
                                        }</button>
                                </div>

                                {this.state.configured ?
                                    <div className="text-center">
                                        <h4 id="successMsg" className="text-green txt-bold">
                                            <FormattedMessage id="app.config.success"
                                                defaultMessage="Configuration successful"
                                                description="Success message after user confirmed that confirmation sound was heard in the HI" />
                                        </h4>
                                    </div> :
                                    <p className="text-center" id="txt1_config">
                                        <FormattedMessage id="app.config.confirmsoundprep"
                                            defaultMessage="If the configuration is successful, the hearing aid will play one or more beep sounds as confirmation."
                                            description="Configuration sound preparation text that is shown before user taps on Configuration" />
                                    </p>}

                                <div className="text-center">
                                    <button id="continueButton" className="largeButton" type="button" onClick={this.handleContinue} hidden={!this.state.configured}>
                                        <FormattedMessage id="app.general.continue"
                                            defaultMessage="Continue"
                                            description="Text of continue button on multiple screens"
                                        />
                                    </button>
                                </div>
                            </div>

                            <RightTheme />
                        </div>

                        <audio ref={this.audioElm} />
                    </div>
                    <Modal show={this.state.intitialModalShow} centered id="modalPrepInfo">
                        <ModalTitle>
                            <div className="text-center mt-4">
                                <img className="hiPrepImage" alt="hi_image" id='imgOpenCloseBatteryDoor' src={`${this.getOpenCloseBatteryDoorImage(this.state.hiUri, this.state.label)}`} />
                            </div>
                        </ModalTitle>

                        <ModalBody>
                            <div id="prepText_1" className={`text-justify ${locale == "ar" ? "arabic" : ""}`}>
                                <p>
                                    <FormattedMessage id={`${this.getPairingTextKey(this.state.hiUri)}`}
                                        defaultMessage={`${this.getPairingText(this.state.hiUri)}`}
                                        description="instruction to prepare the hearing aid for configuration. {device} is automatically set to the device name, {side} can be left or right"
                                        values={{
                                            device: <span id="hiName" className="txt-bold">&nbsp;{this.state.hiName}</span>,
                                            side: <span id="earSidePopup" className={this.state.sideEnum === 1 ? 'leftText' : 'rightText'}>
                                                {this.state.sideEnum === 1 ?
                                                    <FormattedMessage id="app.general.left"
                                                        defaultMessage="left"
                                                        description="left" /> :
                                                    <FormattedMessage id="app.general.right"
                                                        defaultMessage="right"
                                                        description="right" />}

                                            </span>
                                        }} />
                                </p>
                                <p>
                                    <FormattedMessage id="app.config.prepdevicepopup.backintoear"
                                        defaultMessage="Put the hearing aid back into your ear, wait for the startup melody to finish and then press '{where}' on the next screen."
                                        description="Smartphone"
                                        values={{
                                            where: <span className="txt-bold">
                                                <FormattedMessage id="app.general.configure"
                                                    defaultMessage="Configure"
                                                    description="Button caption for Configure buttons" />
                                            </span>,
                                        }} />
                                </p>
                            </div>
                            <div className="text-center justify-content-center">
                                <button className="smallButtonPopup" id="modalOkButton" type="button" onClick={this.initialModalClose}>
                                    <FormattedMessage id="app.general.ok"
                                        defaultMessage="OK"
                                        description="Button caption for OK buttons" />
                                </button>
                            </div>
                        </ModalBody>

                    </Modal>

                    <Modal show={this.state.configureModalShow} centered id="modalConfirmation">
                        <ModalTitle>
                            <div className="text-center mt-4">
                                <FaQuestion id="icon-info" className="iconPopUpSmall" />
                            </div>
                        </ModalTitle>
                        <ModalBody>

                            <div id="txtCnfrm_config" className={`text-center ${locale == "ar" ? "arabic" : ""}`}>
                                <p>
                                    <FormattedMessage id="app.config.beepconfirmationheard"
                                        defaultMessage="Did you hear the confirmation beep sound(s)?"
                                        description="question in beep confirmation sound heard popup" />
                                </p>
                            </div>
                            <div className="text-center">
                                <button type="button" className="smallButtonPopup btnNo" id="btn_No" onClick={this.handleConfigureNo}>
                                    <FormattedMessage id="app.general.no"
                                        defaultMessage="No"
                                        description="Button caption for No buttons" />
                                </button>
                                <button type="button" className="smallButtonPopup btnYes" id="btn_Yes" onClick={this.handleConfigureYes}>
                                    <FormattedMessage id="app.general.yes"
                                        defaultMessage="Yes"
                                        description="Button caption for Yes buttons" />
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal show={this.state.unpowerDeviceModalShow} centered id="modalPowerOff">
                        <ModalTitle>
                            <div className="text-center mt-4">
                                <img className="hiPrepImage" alt="hi_image" id="powerOffImage" src={`${this.getOpenBatteryDoorImage(this.state.hiUri)}`} />
                            </div>
                        </ModalTitle>
                        <ModalBody>
                            <div className={`text-justify ${locale == "ar" ? "arabic" : ""}`} id="prepText_2">
                                <p>
                                    <FormattedMessage id={`${this.getPowerResetTextKey(this.state.hiUri, false)}`}
                                        defaultMessage={`${this.getPowerResetText(this.state.hiUri, false)}`}
                                        description="instruction to turn of the hearing aid. {device} is automatically set to the device name, {side} can be left or right"
                                        values={{
                                            device: <span id="hiName" className="txt-bold">{this.state.hiName}</span>,
                                            side: <span id="earSidePopup" className={this.state.sideEnum === 1 ? 'leftText' : 'rightText'}>
                                                {this.state.sideEnum === 1 ?
                                                    <FormattedMessage id="app.general.left"
                                                        defaultMessage="left"
                                                        description="left" /> :
                                                    <FormattedMessage id="app.general.right"
                                                        defaultMessage="right"
                                                        description="right" />}
                                            </span>
                                        }} />
                                </p>

                                <p>
                                    <FormattedMessage id="app.config.poweroffpopup.leaveaside"
                                        defaultMessage="Leave the hearing aid aside for now and follow the instructions for the other side."
                                        description="lower text in the turn off popup screen" />
                                </p>
                            </div>
                            <div className="text-center">
                                <button className="smallButtonPopup" id="modalOkButton" type="button" onClick={this.unpowerDeviceModalClose}>
                                    <FormattedMessage id="app.general.ok"
                                        defaultMessage="OK"
                                        description="Button caption for OK buttons" />
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal show={this.state.powerDeviceModalShow} centered id="modalPowerOn">
                        <ModalTitle>
                            <div className="text-center mt-4">
                                <img className="hiPrepImage" alt="hi_image" id="powerOnImage" src={`${this.getCloseBatteryDoorImage()}`} />
                            </div>
                        </ModalTitle>
                        <ModalBody>
                            <div className={`text-justify ${locale == "ar" ? "arabic" : ""}`} id="prepText_3">
                                <p>
                                    <FormattedMessage id={`${this.getPowerResetTextKey(this.state.hiUri, true)}`}
                                        defaultMessage={`${this.getPowerResetText(this.state.hiUri, true)}`}
                                        description="instruction to turn on the hearing aid. {side} can be left or right"
                                        values={{
                                            side: <span id="earSidePopup" className={'leftText'}>
                                                <FormattedMessage id="app.general.left"
                                                    defaultMessage="left"
                                                    description="left" />
                                            </span>
                                        }} />
                                </p>
                            </div>
                            <div className="text-center">
                                <button className="smallButtonPopup" id="modalOkButton" type="button" onClick={this.powerDeviceModalClose}>
                                    <FormattedMessage id="app.general.ok"
                                        defaultMessage="OK"
                                        description="Button caption for OK buttons" />
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal show={this.state.loadingModalShow} centered dialogClassName="modal-transparent" backdropClassName="modal-transparent-backdrop-noanimation" animation={false}>
                        <ModalBody>
                            <div className={`text-center ${locale == "ar" ? "arabic" : ""}`}>
                                <div className="mb-3">
                                    {/* translations are set while setting state */}
                                    {this.state.toneLoadText}
                                </div>
                                <Loader
                                    type="Grid"
                                    color="var(--secondary-color)"
                                    height="15%"
                                    width="15%"
                                />
                                <div className="mt-3">
                                    <FormattedMessage id="app.config.loadingmodal.thankyou"
                                        defaultMessage="Thank you for your patience."
                                        description="lower text on loading screen during device configuration" />
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal show={this.state.unrecoverableErrorModalShow} centered id="modalUnrecoverableError">
                        <ModalTitle>
                            <div className="text-center mt-4">
                                <FaExclamationTriangle id="icon-info" className="iconPopUpSmall" style={{ 'color': 'var(--error-color)' }} />
                            </div>
                        </ModalTitle>
                        <ModalBody>
                            <div id="txtUnrecoverableError" className={`text-justify ${locale == "ar" ? "arabic" : ""}`}>
                                <p>
                                    <FormattedMessage id="app.config.error.unrecoverableIntroduction"
                                        defaultMessage="There was an error while generating the sound commands."
                                        description="first line in unrecoverable error popup" />
                                    <FormattedMessage id="app.config.error.unrecoverableHearingAidNotConfigured"
                                        defaultMessage="Your hearing aid has not been configured."
                                        description="second line in unrecoverable error popup" />
                                </p>
                                <p>
                                    <FormattedMessage id="app.config.error.tryagainlater"
                                        defaultMessage="Please try again later or contact support for assistance."
                                        description="third line in unrecoverable error popup" />
                                </p>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal show={this.state.timeoutErrorModalShow} centered id="modalTimeoutError">
                        <ModalTitle>
                            <div className="text-center mt-4">
                                <FaExclamationTriangle id="icon-info" className="iconPopUpSmall" />
                            </div>
                        </ModalTitle>
                        <ModalBody>
                            <div id="txtTimeoutError" className={`text-justify ${locale == "ar" ? "arabic" : ""}`}>
                                <p>
                                    <FormattedMessage id="app.config.error.timeoutIntroduction"
                                        defaultMessage="It is taking unusally long to generate the sound commands."
                                        description="first line in timeout error popup" />
                                </p>
                                <p>
                                    <FormattedMessage id="app.config.error.tryagainnow"
                                        defaultMessage="Please try again or contact support for assistance."
                                        description="second line in timeout error popup" />
                                </p>
                            </div>
                            <div className="text-center">
                                <button className="smallButtonPopup" id="modalOkButton" type="button" onClick={this.timeoutModalClose}>
                                    <FormattedMessage id="app.general.retry"
                                        defaultMessage="Retry"
                                        description="Button caption for Retry buttons" />
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>
                </React.Fragment> : null}
                </div>
            }</IntlConsumer>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
      deviceCount:state.deviceCount,
      custId: state.custId,
      hiUriLeft: state.hiUriLeft,
      hiUriRight: state.hiUriRight,
      tenantId: state.tenantId,
      hiNameRight: state.hiNameRight,
      hiNameLeft: state.hiNameLeft
    };
  }
  
  export default connect(mapStateToProps)(Configuration);