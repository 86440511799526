
export default function RoutCheck(props) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (typeof props.location.state == 'undefined') {
        props.history.push({
            pathname: "/",
            search: "id=" + (params).get("id")
        });
    }
}

