import IntlPolyfill from 'react-intl-polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import "typeface-comfortaa";
import './index.css';
import App from './App';
import { MemoryRouter} from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from "./Components/IntlContext";
import TelemetryProvider from './Components/_helper/TelemetryProvider';
import {Provider}from 'react-redux';
import {createStore} from 'redux'
import {reducer} from './Reducers'


// initialEntries={[{ pathname: "/home" }]}

const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>       
        <TelemetryProvider>
            <IntlPolyfill locales={['en', 'zh']} fallback={<div>Please wait</div>}>
                <BrowserRouter >
                    <IntlProvider>
                        <App />
                    </IntlProvider>
                </BrowserRouter>
            </IntlPolyfill>
        </TelemetryProvider>
    </Provider>
    ,
    document.getElementById('root'));
