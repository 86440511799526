import React, { Component } from 'react'
import Header from './Header'
import { RightTheme, LeftTheme } from './BackgroundTheme'

class Help extends Component {

    constructor(props){
        super(props)
        this.handleBackClick = this.handleBackClick.bind(this);

    }

    handleBackClick(){
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="container-fluid">
                <div >
                    <Header />
                </div>

                <div className="row">
                        <LeftTheme />

                    <div id="content">
                        <button type="button" onClick={this.handleBackClick}>Back</button>
                        Help..!!
                    </div>

                        <RightTheme />
                </div>

            </div>
        )
    }
}
export default Help