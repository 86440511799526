import React, { Component, useContext } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";
import { ai } from "./_helper/TelemetryService";
import {connect} from 'react-redux';
import {getCustomerFriendlyBrandName} from "./_helper/BrandedContent";
import { IntlConsumer } from "./IntlContext";

class Home extends Component {
  constructor(props) {
    super(props);
    
    this.handleSubmit = this.handleSubmit.bind(this);
    
    //set chinse vibe logo as default
    this.state = { id: "", cust: { }, loadingModalShow: false, tenantUrl:"", imageFolder:""};
  }

  componentWillUnmount() {
    ai.stopPageViewTracking("Home");
  }

  componentDidMount() {
    ai.startPageViewTracking();
    ai.trackPageView("Home");
    this.setState({ id: "custId"});
    ai.trackEvent("Home loaded", { id: this.props.custId,  });
    
   
  }


  handleSubmit(event) {
    ai.trackEvent("Start", { id: this.props.custId });
    event.preventDefault();
    this.props.history.push({
      pathname: "/onboarding",
      search:'id='+this.props.custId,
      state: {
        cust: this.state.cust,
        imageFolder:this.state.imageFolder
      }
    });
  }
  
  handleDefaultNaming(naming) {
    if (naming == ".")
      naming = "";
    return naming
  }

  render() {
    return (
      <div>
        <div>
          <div className="backup" id="background_home">
            {" "}
          </div>
          <IntlConsumer>
              {({ locale }) => 
          <div className={`container-fluid ${locale == "ar" ? "arabic" : ""}`}>
            {this.state.loadingModalShow ? (
              ""
            ) : (
              <div>
                <h3 className="title mt-5 ml-3 mr-3 text-truncate" id="title_home">
                  <FormattedMessage
                    id="app.home.hello"
                    defaultMessage="Hello {who}"
                    description="Greeting on homescreen. {who} includes the customers name as retrieved from backend"
                    values={{ who: this.handleDefaultNaming(this.props.name)}}
                  />
                </h3>
                <div className="col text-justify">
                  <FormattedMessage
                    id="app.home.thankyou"
                    defaultMessage="Thank you for using a {what} product."
                    description="First line of welcome text on homescreen"
                    values={{ what: getCustomerFriendlyBrandName(this.props.tenant) }}
                  >
                    {(...content) => (
                      <div className="mt-3" id="txt1_home">
                        {content}
                      </div>
                    )}
                  </FormattedMessage>

                  <FormattedMessage
                    id="app.home.adjust"
                    defaultMessage="We will now adjust your {deviceCount, plural, one {hearing aid} other {hearing aids}} so that they perfectly fit your individual needs."
                    description="Second line of welcome text on homescreen"
                    values={{ deviceCount: this.props.deviceCount }}
                  >
                    {(...content) => (
                      <div className="mt-3" id="txt2_home">
                        {content}
                      </div>
                    )}
                  </FormattedMessage>

                  <FormattedMessage
                    id="app.home.assembly"
                    defaultMessage="Before we start, please follow the assembly instructions in the manual that came with your {deviceCount, plural, one {hearing aid} other {hearing aids}}."
                    description="Third line of welcome text on homescreen"
                    values={{ deviceCount: this.props.deviceCount }}
                  >
                    {(...content) => (
                      <div className="mt-3" id="txt3_home">
                        {content}
                      </div>
                    )}
                  </FormattedMessage>

                  <FormattedMessage
                    id="app.home.begin"
                    defaultMessage="Please press '{start}' to begin, once you are ready."
                    description="Fourth line of welcome text on homescreen"
                    values={{
                      start: (
                        <span key="k" className="txt-bold">
                          <FormattedMessage
                            id="app.home.start"
                            defaultMessage="Start"
                          />
                        </span>
                      )
                    }}
                  >
                    {(...content) => (
                      <div className="mt-4" id="txt4_home">
                        {content}
                      </div>
                    )}
                  </FormattedMessage>

                  <button
                    type="submit"
                    id="btn_home"
                    className="startButton" 
                    onClick={this.handleSubmit}
                  >
                    <FormattedMessage
                      id="app.home.start"
                      defaultMessage="Start"
                    />
                  </button>
                </div>
                <div></div>
              </div>
            )}
          </div>
        }</IntlConsumer>
        </div>
        <Modal
          show={this.state.loadingModalShow}
          centered
          dialogClassName="modal-transparent modal-90w"
          backdropClassName="modal-transparent-backdrop-noanimation"
          animation={false}>
          <ModalBody>
            <div className="text-center">
              <Loader
                type="Grid"
                color="var(--secondary-color)"
                height="15%"
                width="15%"
              />
              <div className="mt-3">
                <FormattedMessage
                  id="app.home.loading"
                  defaultMessage="One moment"
                  description="Loading overlay text(while data is received from backend)"
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    deviceCount:state.deviceCount,
    name:state.name,
    custId: state.custId,
    tenant: state.tenantId
  };
}

export default connect(mapStateToProps)(Home);
