import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";

import localeData_en from 'react-intl/locale-data/en';
import localeData_zh from 'react-intl/locale-data/zh';
import localeData_ja from 'react-intl/locale-data/ja';
import localeData_ko from 'react-intl/locale-data/ko';
import localeData_pt from 'react-intl/locale-data/pt';
import localeData_ar from 'react-intl/locale-data/ar';
import localeData_vi from 'react-intl/locale-data/vi';
import localeData_th from 'react-intl/locale-data/th';
import messages_zhcn from '../Translations/zh-cn.json';
import messages_zhtw from '../Translations/zh-tw.json';
import messages_jajp from '../Translations/ja-JP.json';
import messages_kokr from '../Translations/ko-kr.json'
import messages_pt from '../Translations/pt.json';
import messages_ar from '../Translations/ar.json';
import messages_vi from '../Translations/vi.json'
import messages_th from '../Translations/th.json'

import { ai } from './_helper/TelemetryService';

addLocaleData([...localeData_en, ...localeData_zh, ...localeData_ja, ...localeData_ko, ...localeData_pt, ...localeData_ar, ...localeData_vi, ...localeData_th]);

const { Provider, Consumer } = React.createContext();
const localeEn = 'en';
const localePt = 'pt';
const localeJa = 'ja';
const localeKo = 'ko';
const localeVi = 'vi';
const localeAr = 'ar';
const localeTh = 'th';
const localeZh = 'zh';
const localeZhCn = 'zh-CN'; //simplified
const localeZhSg = 'zh-SG'; //simplified
const localeZhTw = 'zh-TW'; //traditional
const localeZhKh = 'zh-HK'; //traditional
const localeZhMo = 'zh-MO'; //traditional

const localeParameterMapping = {
    'en-us': localeEn,
    'en-gb': localeEn,
    'ja-jp': localeJa,
    'ko-kr': localeKo,
    'zh-ch': localeZhTw,
    'vi-vi': localeVi 
}

const localeMapping = {
    [localeEn]: null,
    [localePt]: messages_pt,
    [localeJa]: messages_jajp,
    [localeKo]: messages_kokr,
    [localeVi]: messages_vi,
    [localeAr]: messages_ar,
    [localeTh]: messages_th,
    [localeZh]: messages_zhcn,
    [localeZhCn]: messages_zhcn,
    [localeZhSg]: messages_zhcn,
    [localeZhTw]: messages_zhtw,
    [localeZhKh]: messages_zhtw, 
    [localeZhMo]: messages_zhtw
};

    
class IntlProviderWrapper extends React.Component {
    constructor(...args) {
        super(...args);

        this.switchToEnglish = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeEn});
            this.setState({ locale: localeEn, messages: null });
        }

        this.switchToChineseSimplified = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeZhCn});
            this.setState({ locale: localeZhCn, messages: messages_zhcn });
        }

        this.switchToChineseTraditional = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeZhTw});
            this.setState({ locale: localeZhTw, messages: messages_zhtw });
        }

        this.switchToJapanese = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeJa});
            this.setState({ locale: localeJa, messages: messages_jajp });
        }

        this.switchToKorean = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeKo});
            this.setState({ locale: localeKo, messages: messages_kokr });
        }

        this.switchToPortuguese = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localePt});
            this.setState({ locale: localePt, messages: messages_pt });
        }

        this.switchToArabic = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeAr});
            this.setState({ locale: localeAr, messages: messages_ar });
        }

        this.switchToVietnamese = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeVi});
            this.setState({ locale: localeVi, messages: messages_vi });
        }

        this.switchToThai = () => {
            ai.trackEvent("Change language", {'old': this.state.locale, 'new': localeTh});
            this.setState({ locale: localeTh, messages: messages_th });
        }

        const browserLanguages = navigator.languages;
        for (const browserLanguage of browserLanguages) {
            console.log('lang', browserLanguage);
        }

        //fallback if nothing matches to browser defined languages
        var locale = localeZhCn;
        var messages = messages_zhcn;

        //set the language if there is language parameter provided, or else set to browser language
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const language = params.get("lang");
        if (language != null && language in localeParameterMapping) {
            const actualLang = localeParameterMapping[language];
            messages = localeMapping[actualLang];
            locale = actualLang;
            ai.traceInfo("Set language", { 'languageParameter': language });
        } else {
            let found = false;
            for (const browserLanguage of browserLanguages) {
                for (const language in localeMapping) {
                    if (browserLanguage.startsWith(language)) {
                        let actualLang = language;
                        if (language === localeZh) {
                            actualLang = browserLanguage;
                        } 
                        messages = localeMapping[actualLang];
                        locale = actualLang;
                        ai.traceInfo("Set language", { 'browserLocales': browserLanguages, 'detected': browserLanguage });
                        found = true;
                        break;
                    }
                }
                if (found) break;
            }
        }       

        // pass everything in state to avoid creating object inside render method (like explained in the documentation)
        this.state = {
            locale: locale,
            messages: messages,
            switchToEnglish: this.switchToEnglish,
            switchToChineseSimplified: this.switchToChineseSimplified,
            switchToChineseTraditional: this.switchToChineseTraditional,
            switchToJapanese: this.switchToJapanese,
            switchToKorean: this.switchToKorean,
            switchToPortuguese: this.switchToPortuguese,
            switchToArabic: this.switchToArabic,
            switchToVietnamese: this.switchToVietnamese,
            switchToThai: this.switchToThai,
        };
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        return (
            <Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            </Provider>
        );
    }
}

export { IntlProviderWrapper as IntlProvider, Consumer as IntlConsumer };