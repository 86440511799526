import React, { Component } from 'react'

class TermsAndConditions extends Component {
    render() {
        return (
            <div className="legalText text-justify legalContent">
                <div id="scope">
                    <span className="txt-bold legalTextParHeader">
                        1. Scope
                    </span>
                    These terms and conditions and license agreement (“License”) is between you (“You” or “Your”) and applicable Sivantos entity making available to you the Software and Documentation (“SIVANTOS”). This License sets forth the terms and conditions for YOUR use of the Software and Documentation.
                </div>

                <div id="doc" className="mt-1">
                    <span className="txt-bold legalTextSubParHeader">
                        a) “Documentation”
                    </span>
                    means any technical documentation made available to you by SIVANTOS describing the features and functions of the Software.
                </div>

                <div id="doc" className="mt-1">
                    <span className="txt-bold legalTextSubParHeader">
                        b) “Software”
                    </span>
                    means SIVANTOS software made available to you by way of a software application or in object code format.
                </div>

                <div id="acceptance" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        2. Acceptance
                    </span>
                    By clicking the “I ACCEPT” or similar button when accessing, downloading or installing the Software, You agree that this License will exclusively govern SIVANTOS’s license and Your use of the Software and Documentation unless You have a separate applicable written agreement with SIVANTOS governing the same.  If You are accepting this License on behalf of another person, company or other legal entity, whether as a health care provider, audiologist, employee, contractor, distributor, reseller, agent or otherwise, You represent and warrant that You have full authority to bind them.  If You do not agree or have the authority to agree to the terms of this License, do not download, install, copy, access, or use the Software or Documentation, or click the “I ACCEPT” or similar button, and where applicable, promptly return the Software and Documentation to the party from whom it was obtained.
                </div>

                <div id="grant" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        3. License Grant
                    </span>
                    SIVANTOS grants to You a personal, non-exclusive, non-transferrable, worldwide, limited and revocable license, without the right to sublicense, to i) install and/or use the Software only for Your internal business purpose (if you are a healthcare provider or audiologist) or for the purpose relating to YOUR purchase of hearing aids or related services from SIVANTOS, and ii) use the Documentation in support of Your use of the Software.
                    Should you download the Software, You shall be entitled to install and use the program on any number of computers and to make one (1) back-up copy only.
                    This License does not grant any rights to obtaining future upgrades, updates or supplements to the Software. If upgrades, updates or supplements to the Software are obtained, however, the use of such upgrades or updates is governed by this License and the amendments to it unless other terms accompany the upgrades, updates or supplements in which case those terms apply.
                </div>

                <div id="restricions" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        4. License Restrictions
                    </span>
                    You shall not, nor shall You allow any third party to:  a) reverse-engineer, decompile or disassemble the Software or otherwise reduce it to human-readable format; b) remove or conceal any product identification, proprietary, intellectual property or other notices in the Software or Documentation; c) assign or otherwise transfer, in whole or in part, the Software or Documentation licenses to another party; or d) modify, adapt or create a derivative work of the Software or Documentation.
                </div>

                <div id="ip" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        5. Intellectual Property Rights
                    </span>
                    The Software and Documentation is licensed, not sold, to You.  It is protected by intellectual property laws and treaties worldwide, and contains trade secrets, in which SIVANTOS and its licensors reserve and retain all rights not expressly granted to You.  No right, title or interest to any trademark, service mark, logo or trade name, of SIVANTOS or its licensors is granted to You.
                </div>

                <div id="limitedwarranty" className="mt-1 txt-bold">
                    <span className="txt-bold legalTextParHeader">
                        6. Limitted Warranty
                    </span>
                    SIVANTOS assumes no warranty regarding any defect in title or quality of the Software or the Documentation. Such disclaimer shall not apply in case of a fraudulent concealment of such defect. Software descriptions shall not constitute a guarantee of any features, even if they refer to DIN and/or other standards. SIVANTOS does not warrant that Your use of the Software will be error-free or uninterrupted.  SIVANTOS’ warranty obligations will be voided if You or any third parties misuse or modify the Software or use the Software for any purpose other than permitted by this License. TO THE EXTENT PERMITTED BY APPLICABLE LAWS, THIS IS YOUR SOLE AND EXCLUSIVE WARRANTY AND REMEDY. SIVANTOS SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING THOSE OF MERCHANTABILITY, ACCEPTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                </div>

                <div id="limitedLiablity" className="mt-1 txt-bold">
                    <span className="txt-bold legalTextParHeader">
                        7. Limitation of Liability
                    </span>
                    SIVANTOS and its licensors are not liable for: a) any indirect, consequential, incidental, exemplary or special damages; b) loss or corruption of data; c) loss of actual or anticipated revenues, profits, goodwill or savings; d) procurement of substitute goods and/or services and e) interruption to business.
                <div className="mt-1"></div>
                    The limitations and exclusions above shall not apply to liability for death or personal injury caused by negligence, willful misconduct or fraud of SIVANTOS or any other liability of SIVANTOS that cannot be excluded under applicable laws.
                </div>

                <div id="termination" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        8. Termination
                    </span>
                    SIVANTOS may terminate this License at any time for YOUR breach of this License.  Upon termination of this License, You shall promptly cease the use of the Software and Documentation and where applicable, return or destroy all copies of the Software and Documentation.
                </div>

                <div id="ossoftware" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        9. Termination
                    </span>
                    The Software is developed using or includes the following open source software:
                    <ul>
                        <li>
                            sdfk
                        </li>
                    </ul>
                    The terms of the open source licenses apply to the Software and will prevail in the event of any inconsistency with this License. 
                </div>

                <div id="exportcontrol" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        10. Export Controls
                    </span>
                    You shall comply with all applicable national and international (re-) export control regulations. In any event, User shall comply with the (re-) export control regulations of the Federal Republic of Germany, of the European Union and of the United States of America.
                </div>

                <div id="dataprivacy" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        11. Data Privacy
                    </span>
                    You have sole responsibility for the personal data you choose to manage and/or store using the Software and agree to comply with all applicable data privacy laws.  SIVANTOS is only responsible for personal data it explicitly obtains from you (or through your authorized persons such as your healthcare provider or audiologist) for your use of the Software and/or for Sivantos to provide its hearing aids and related services to You.  In such cases, the use of your personal data by SIVANTOS shall be subject to its prevailing Privacy Policy which you will be required to accept before proceeding further.
                </div>

                <div id="general" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        12. General
                    </span>
                    This License will be construed pursuant to the laws of Singapore. The application of the United Nations Convention on Contracts for the International Sale of Goods April 11, 1990 shall be excluded. Save for affiliates and subsidiaries of SIVANTOS, this License shall not be enforceable, by virtue of the Contracts (Rights of Third parties) Act (chapter 53B) or otherwise, by any person who is not a party to this License. 
                    <div className="mt-1" />
                    To the extent that any Section of this License, or part thereof, is determined to be invalid or unenforceable, the remainder of this License shall remain in full force and effect.  This License may not be changed except by a written amendment executed by an authorized representative of each party.  In the event of a dispute between the English and non-English version of the License (where translated for local requirements), the English version of this License shall govern, to the extent permitted by applicable laws.  This License represents the entire agreement and understanding between SIVANTOS and You with respect to the Software and Documentation.  It supersedes any previous communications, representations or agreements between SIVANTOS and You and prevails over any conflicting or additional terms in any quote, purchase order, acknowledgment, or similar communication between the parties.
                </div>

                <div id="infogathering" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        13. Information Gathering
                    </span>
                    You are aware and accept that the Software is equipped with third party components and components that enable the Software to gather technical information through a third-party provider and report this technical information back to SIVANTOS. You can deactivate this function in the User Preferences.
                    <div className="mt-1" />
                    The technical information gathered is information about the configuration of the Your IT installation including, but not limited to, the configuration of the computer on which the Software is installed, the use of the Software and the country where the computer is located.
                    <div className="mt-1" />
                    SIVANTOS will not gather any personal data as part of such information gathering process. SIVANTOS uses the technical information gathered to improve the Software.
                </div>

            </div>
        )
    }
}
export default TermsAndConditions