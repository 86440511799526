import React from 'react';
import RightImage from '../Assets/Images/Right-bg.png'
import LeftImage from '../Assets/Images/Left-bg.png'

export const RightTheme = () => {
    return (
        <div id="rightDesktopTheme" className="col-2 text-right pr-0">
            <img src={RightImage} alt="rightImage"/>
        </div>
    )
}

export const LeftTheme = () => {
    return (
        <div id="leftDesktopTheme" className=" col-2 text-left pl-0">
            <img src={LeftImage} alt="leftImage"/>
        </div>
    )
}