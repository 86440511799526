import React, { Component } from 'react'
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap'
import ProgressBar from "./ProgressBar";
import SoundTrack from '../Assets/Tones/soundCheck.mp3'
import { RightTheme, LeftTheme } from './BackgroundTheme'
import { isMobile, isTablet, isChrome } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { ai } from './_helper/TelemetryService';
import RouterCheck from './RouterCheck'
import Loader from 'react-loader-spinner'
import {connect} from 'react-redux';

import SoundCheckComputerAnimation from '../Assets/Images/Soundcheck_Computer_Animation.gif';
import SoundCheckComputerStill from '../Assets/Images/Soundcheck_Computer_Still.png';
import SoundCheckMobileAnimation from '../Assets/Images/Soundcheck_Mobile_Animation.gif';
import SoundCheckMobileStill from '../Assets/Images/Soundcheck_Mobile_Still.png';
import { IntlConsumer } from './IntlContext';



class SoundCheck extends Component {
    constructor(props) {
        super(props);
        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleModalButtonClick = this.handleModalButtonClick.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.audioElm = React.createRef();

        let deviceText = "computer";
        if (isMobile)
            deviceText = "smartphone";
        else if (isTablet)
            deviceText = "tablet";
        var active = SoundCheckMobileAnimation;
        var inactive = SoundCheckMobileStill;

       

        if (!isTablet && !isMobile) {
            active = SoundCheckComputerAnimation;
            inactive = SoundCheckComputerStill;           
        }


        this.state = {
            playClicked: false, btnDisabled: true, modalShow: false, 
            audioPlay: false, deviceText: deviceText, activeImage: active, 
            inactiveImage: inactive, showLoader:false }
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    componentDidMount() {
        RouterCheck(this.props);
        ai.startPageViewTracking();
        ai.trackPageView('Soundcheck');
    }

    componentWillUnmount() {
        ai.stopPageViewTracking('Soundcheck');
    }

    playClick = () => {
         this.audioElm.current.src = SoundTrack;
         
         

        this.audioElm.current.onerror = (error) => {
            var errorDescription = Object.keys(Object.getPrototypeOf(error.currentTarget.error)).find(key => error.currentTarget.error[key] === error.currentTarget.error.code);
            ai.traceError("Error while playing soundcheck", errorDescription, { 'id': this.props.custId  });
        }

        if (this.state.playClicked === true) {
            this.audioElm.current.pause();
            ai.trackEvent("Soundcheck pause", { 'id': this.props.custId });
            this.setState({ playClicked: false, audioPlay: false, btnDisabled: true, showLoader: false })
            console.log("should pause");

            this.audioElm.current.oncanplaythrough = () => {};
            this.audioElm.current.onloadstart = () => {};
        }
        else {
            this.audioElm.current.load();
            ai.trackEvent("Soundcheck play", { 'id': this.props.custId });
            this.audioElm.current.oncanplaythrough = () => {
                ai.trackEvent("Soundcheck canplaythrough", { 'id': this.props.custId });
                this.setState({ playClicked: true, audioPlay: true, showLoader: false, btnDisabled: false })
                this.audioElm.current.play();
            }
            this.audioElm.current.onloadstart = () => {
                console.log("load");
                ai.trackEvent("Soundcheck onloadstart", { 'id': this.props.custId });
                this.setState({ showLoader: true, btnDisabled:true})
            }           
        
            this.scrollToBottom();
        }
    }

    stop

    handleYesClick(event) {
        event.preventDefault();
        ai.trackEvent("Soundcheck yes", { 'id': this.props.custId });
        this.props.history.push({
            pathname: "/configuration",
            search: 'id=' + this.props.custId,
            state: {
                cust: this.props.location.state.cust,
            }
        });

    }

    handleNoClick(event) {
        ai.trackEvent("Soundcheck no", { 'id': this.props.custId });
        event.preventDefault();
        this.setState({ modalShow: true })
    }

    handleModalButtonClick() {
        this.setState({ modalShow: false })
    }

    render() {
        return (
            <div>
                {this.props.location.state ? <React.Fragment>
                    <ProgressBar deviceCount={this.props.deviceCount} currentStep="2" />
                    <IntlConsumer>
                        {({locale}) =>
                    <div>
                    <div className="container-fluid text-center">
                        <div className="row">
                            <LeftTheme />
                            <div id="content" className={`${locale == "ar" ? "arabic" : ""}`}>
                                <div className="text-center" id="txt1_SoundCheck">
                                    <FormattedMessage id="app.soundcheck.commands"
                                        defaultMessage="Your {deviceCount, plural, one {hearing aid} other {hearing aids}} will be configured via sound commands sent from your {what} speakers."
                                        description="First instruction on soundcheck page. {what} is the detected device type, e.g. computer/smartphone/tablet"
                                        values={{
                                            what: isMobile ?
                                                <FormattedMessage id="app.general.smartphone"
                                                    defaultMessage="smartphone"
                                                    description="Smartphone" />
                                                :
                                                isTablet ?
                                                    <FormattedMessage id="app.general.tablet"
                                                        defaultMessage="tablet"
                                                        description="Tablet" />
                                                    :
                                                    <FormattedMessage id="app.general.computer"
                                                        defaultMessage="computer"
                                                        description="Computer" />,
                                            deviceCount: this.props.deviceCount
                                        }} />
                                    <br />
                                    <div className="mt-3" />
                                    <FormattedMessage id="app.soundcheck.checkspeaker"
                                        defaultMessage="In this step, you need to check and confirm that your speakers are working."
                                        description="Second instruction on soundcheck page" />
                                </div>
                                <div className="earImage">
                                    {this.state.showLoader ?
                                    <React.Fragment>
                                        <Loader
                                            type="Grid"
                                            color="var(--secondary-color)"
                                            height="86px"
                                            width="160px"
                                        /></React.Fragment>:
                                        <React.Fragment>
                                    {this.state.playClicked ?
                                        <img src={this.state.activeImage} id="imgPlaying" alt="ear" />
                                        : <img id="imgNotPlaying" src={this.state.inactiveImage} alt="ear" />}</React.Fragment>}
                                        
                                </div>
                                <div>
                                    <button className="smallButton" id="btnPlayTone" type="button" onClick={this.playClick}>
                                        {
                                            this.state.playClicked ?
                                                <FormattedMessage id="app.general.stop"
                                                    defaultMessage="Stop"
                                                    description="Button captin for Stop buttons" /> :
                                                <FormattedMessage id="app.general.play"
                                                    defaultMessage="Play"
                                                    description="Button caption for Play buttons" />
                                        }

                                    </button>
                                </div>
                                <div className="text-center" id="txt2_SoundCheck">
                                    <FormattedMessage id="app.soundcheck.pressplay"
                                        defaultMessage="Set the volume of your {what} to maximum and press '{where}'."
                                        description="First instruction on soundcheck page. {what} is the detected device type, e.g. computer/smartphone/tablet"
                                        values={{
                                            what: isMobile ?
                                                <FormattedMessage id="app.general.smartphone"
                                                    defaultMessage="smartphone"
                                                    description="Smartphone" />
                                                :
                                                isTablet ?
                                                    <FormattedMessage id="app.general.tablet"
                                                        defaultMessage="tablet"
                                                        description="Tablet" />
                                                    :
                                                    <FormattedMessage id="app.general.computer"
                                                        defaultMessage="computer"
                                                        description="Computer" />,
                                            where: <span className="txt-bold"><FormattedMessage id="app.general.play"
                                                defaultMessage="Play"
                                                description="Button caption for Play buttons" /></span>,
                                        }} />
                                </div>
                                <div className="confirm_sndChk text-center">
                                    <div className={this.state.btnDisabled ? "confirmtxt_sndChk text-center" : "confirmtxt_sndChk text-center txt-bold"} id="txt3_SoundCheck">
                                        <FormattedMessage id="app.soundcheck.soundheard"
                                            defaultMessage="Can you hear the sound?"
                                            description="Final question on soundcheck page" />
                                    </div>
                                    <div className=" text-center">
                                        <button className={'smallButton ' + (!this.state.btnDisabled ? 'btnNo' : null)} id="btn_No" type="button" onClick={this.handleNoClick} disabled={this.state.btnDisabled}>
                                            <FormattedMessage id="app.general.no"
                                                defaultMessage="No"
                                                description="Button caption for No buttons" />
                                        </button>
                                        <button className={'smallButton ' + (!this.state.btnDisabled ? 'btnYes' : null)} id="btn_Yes" type="button" onClick={this.handleYesClick} disabled={this.state.btnDisabled}>
                                            <FormattedMessage id="app.general.yes"
                                                defaultMessage="Yes"
                                                description="Button caption for Yes buttons" />
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <RightTheme />
                        </div>
                        <div className="audioPlayer">
                            {/* <ReactPlayer  ref={this.audioElm} playing={this.state.audioPlay} url={SoundTrack} loop={true} onEnded={this.handleAudioEnd} /> */}
                            <audio ref={this.audioElm} loop />
                        </div>
                        <div style={{ float: "left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>
                    <Modal id="modalAdditionalInfo" show={this.state.modalShow} centered onHide={this.handleModalButtonClick}>
                        <ModalTitle>
                            <div className="text-center mt-4 popup-header">
                                <p> <FormattedMessage id="app.soundcheck.notheardheader"
                                    defaultMessage="Please try the following"
                                    description="tip pop header" /></p>
                            </div>
                        </ModalTitle>
                        <ModalBody>
                            <ul id="listAdditionalInfor">
                                <li>
                                    <FormattedMessage id="app.soundcheck.tipdevicevolume"
                                        defaultMessage="Set the {what} volume to maximum"
                                        description="device volume to max tip"
                                        values={{
                                            what: isMobile ?
                                                <FormattedMessage id="app.general.smartphone"
                                                    defaultMessage="smartphone"
                                                    description="Smartphone" />
                                                :
                                                isTablet ?
                                                    <FormattedMessage id="app.general.tablet"
                                                        defaultMessage="tablet"
                                                        description="Tablet" />
                                                    :
                                                    <FormattedMessage id="app.general.computer"
                                                        defaultMessage="computer"
                                                        description="Computer" />
                                        }} />
                                </li>
                                <li>
                                    <FormattedMessage id="app.soundcheck.tipdevicetoear"
                                        defaultMessage="Hold the {what} closer to your ear"
                                        description="hold devic closer to ear tip. {what} can be computer/smartphone/tablet"
                                        values={{
                                            what: isMobile ?
                                                <FormattedMessage id="app.general.smartphone"
                                                    defaultMessage="smartphone"
                                                    description="Smartphone" />
                                                :
                                                isTablet ?
                                                    <FormattedMessage id="app.general.tablet"
                                                        defaultMessage="tablet"
                                                        description="Tablet" />
                                                    :
                                                    <FormattedMessage id="app.general.computer"
                                                        defaultMessage="computer"
                                                        description="Computer" />
                                        }} />

                                </li>
                                <li><FormattedMessage id="app.soundcheck.tipasksomebody"
                                    defaultMessage="Ask somebody to confirm whether the sound is playing or not"
                                    description="ask somebody else tip" />
                                </li>
                                <li>
                                    <FormattedMessage id="app.soundcheck.tipcontactsupport"
                                        defaultMessage="Contact Support"
                                        description="contact support tip" />
                                </li>
                            </ul>
                            <div className="text-center">
                                <button className="smallButton" id="btn_sndChk_popup" type="button" onClick={this.handleModalButtonClick}>
                                    <FormattedMessage id="app.general.ok"
                                        defaultMessage="OK"
                                        description="Button caption for OK buttons" />
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>
                    </div>
                    }
                    </IntlConsumer>
                </React.Fragment> : null}
            </div>
        );
    }
}
function mapStateToProps(state){
    return {
      deviceCount:state.deviceCount,
      custId: state.custId,
      tenantId: state.tenantId,
    };
  }
  
  export default connect(mapStateToProps)(SoundCheck);
