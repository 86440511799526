import React, { Component } from "react";
import {
  FaRegAngry,
  FaRegFrownOpen,
  FaRegMeh,
  FaRegSmile,
  FaRegTimesCircle,
  FaRegGrinHearts
} from "react-icons/fa";
import { Modal, ModalBody } from "react-bootstrap";
import { ai } from "./_helper/TelemetryService";
import { FormattedMessage } from "react-intl";
import { IntlConsumer } from "./IntlContext";

export default class Feedback extends Component {
  state = {
    rating: "0",
    feedbackText: "",
    loadingModalShow: true,
    btnDisabled: true,
    textDisabled: true,
    ShowFeedbackOptions: true
  };

  resetState = () => {
    this.setState({
      rating: "0",
      feedbackText: "",
      btnDisabled: true,
      textDisabled: true,
      ShowFeedbackOptions: false
    });
  };
  close = () => {
    this.setState({
      loadingModalShow: false,
      ShowFeedbackOptions: true,
      rating: "0",
      feedbackText: "",
      textDisabled: true,
      btnDisabled: true
    });
    this.props.handleFeedbackModalClose();
  };

  NoAction = () => {};

  handleFeedbackSubmit = () => {
    ai.trackEvent("Feedback", {
      Rating: this.state.rating,
      Message: this.state.feedbackText,
      Page: this.props.intiatedPage
    });
    this.resetState();
  };

  handleEmoji = value => {
    this.setState({
      rating: value,
      textDisabled: false,
      btnDisabled: false
    });
  };

  handleChange = event => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      validationError: ""
    });
  };

  render() {
    return (
      <IntlConsumer>
        {({locale}) =>
      <Modal
        show={this.state.loadingModalShow}
        id="feedbackModal"
        centered
        className={`${locale == "ar" ? "arabic" : ""}`}
        onHide={this.NoAction}
      >
        <ModalBody>
          <FaRegTimesCircle className="closeButton" onClick={this.close} />
          {this.state.ShowFeedbackOptions ? (
            <div>
              <div className="text-center font-weight-bold feedbackHeader">
                <FormattedMessage
                  id="app.feedback.feedbackHeader"
                  defaultMessage="Send us your feedback"
                  description="Send us your feedback"
                />
              </div>
              <div className="text-justify ">
                <FormattedMessage
                  id="app.feedback.feedbackRequest"
                  defaultMessage="We would love to hear about your experience with myVibe to
                  constantly improve our service for you."
                  description="feedback request"
                />
              </div>

              <div>
                <div className="feedbackEmoji">
                  <FaRegAngry
                  id="btnRating1"
                    onClick={() => this.handleEmoji("1")}
                    className={
                      this.state.rating === "1"
                        ? "selecteSmiley"
                        : "unselectedSmiley"
                    }
                  />{" "}
                  <FaRegFrownOpen
                   id="btnRating2"
                    onClick={() => this.handleEmoji("2")}
                    className={
                      this.state.rating === "2"
                        ? "selecteSmiley"
                        : "unselectedSmiley"
                    }
                  />{" "}
                  <FaRegMeh
                   id="btnRating3"
                    onClick={() => this.handleEmoji("3")}
                    className={
                      this.state.rating === "3"
                        ? "selecteSmiley"
                        : "unselectedSmiley"
                    }
                  />{" "}
                  <FaRegSmile
                   id="btnRating4"
                    onClick={() => this.handleEmoji("4")}
                    className={
                      this.state.rating === "4"
                        ? "selecteSmiley"
                        : "unselectedSmiley"
                    }
                  />{" "}
                  <FaRegGrinHearts
                   id="btnRating5"
                    onClick={() => this.handleEmoji("5")}
                    className={
                      this.state.rating === "5"
                        ? "selecteSmiley"
                        : "unselectedSmiley"
                    }
                  />
                </div>
                <br />
                <div>

                  <FormattedMessage
                    id="app.feedback.details"
                    defaultMessage="Please tell us more"
                    description="feedback details text area placeholder"
                  >
                    {placeholder =>
                      <textarea
                        className="textArea"
                        id="txtfeedback"
                        name="feedbackText"
                        value={this.state.handleFeedbackMessage}
                        onChange={this.handleChange}
                        disabled={this.state.textDisabled}
                        placeholder={placeholder}
                      ></textarea>
                    }
                  </FormattedMessage>
                </div>
              </div>
              <div className="text-center">
                <button
                  className="smallButton"
                  id="btnFeedbackSubmit"
                  type=" submit "
                  disabled={this.state.btnDisabled}
                  onClick={this.handleFeedbackSubmit}
                >
                  <FormattedMessage
                  id="app.feedback.submit"
                  defaultMessage="Submit"
                  description="Submit button caption"
                />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="text-center font-weight-bold">
                <FormattedMessage
                  id="app.feedback.feedbackThanks"
                  defaultMessage="Thank you"
                  description="Thank you message"
                />
              </div>
              <div className="text-justify text-center" id="txtFeedbackReceived">
                <FormattedMessage
                  id="app.feedback.feedbackRequestRecieved"
                  defaultMessage="We have received your feedback"
                  description="feedback recieved message"
                />
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
        }
      </IntlConsumer>
    );
  }
}
