import React, { Component } from 'react';
import { Routes } from './Components/Routes'
import { connect } from 'react-redux'
import { getAuthHeader } from "./Components/_helper/AuthHeader";
import { ai } from "./Components/_helper/TelemetryService";
import { Modal, ModalBody } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";
import Header from "./Components/Header";
import ServerError from './Components/ServerError';
import NotFound from './Components/NotFoundError';


class App extends Component {

  constructor(props) {
    super(props)
    this.state = { loadingModalShow: true, tenantId: "", errorPage: "" }
    ai.traceInfo("load app");
  }

  

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {


    let search = window.location.search;
    let params = new URLSearchParams(search);
    let custId = params.get("id");
    if (custId !== null) {
      custId = custId.substr(0, 7)

      var url = this.URLBuilder(custId);
      ai.traceInfo("start fetch cust data", { id: "custId", url: url });
      fetch(url, {
        headers: getAuthHeader()
      })
        .then(result => {
          if (result.status === 404) {
            ai.traceInfo("navigating to not found error");
            this.setState({ loadingModalShow: false, errorPage: <NotFound /> })
          } else if (result.status !== 200) {
            ai.traceInfo("navigating to server error", { code: result.status });
            this.setState({ loadingModalShow: false, errorPage: <ServerError /> })
          } else {
            result.json().then(body => {
              this.setState({ tenantId: body.tenantId })
              var deviceCount = 2;
              if (body.hiUriLeft == null || body.hiUriRight == null)
                deviceCount = 1;
              this.props.dispatch({ type: 'Customer_Payload', data: body, custId, deviceCount })
              ai.traceInfo("received cust data", { result: body });
              
              if (body.customerName) {
                this.setState({
                  loadingModalShow: false
                });
              } else this.setState({ loadingModalShow: false })
            });
          }
        })
        .catch(error => {
          ai.traceError("error while parsing cust response", JSON.stringify(error, Object.getOwnPropertyNames(error)), { ID: custId });
          console.log("error from server", JSON.stringify(error, Object.getOwnPropertyNames(error)));
          this.setState({ loadingModalShow: false, errorPage: <ServerError /> })
        });
    }
    else {
      ai.traceInfo("Customer ID is empty");
      this.setState({ loadingModalShow: false, errorPage: <NotFound /> })
    }
  }

  URLBuilder(id) {
    var url = process.env.REACT_APP_USECASEAPI + "/settings/?id=" + id;
    return url;
  }

  render() {
    var errorPage = this.state.errorPage
    return (
      <div>
        {this.state.loadingModalShow ? (
          ""
        ) : <div>
            {this.state.errorPage !== "" ? errorPage :
              <div>
                <Header tenantId={this.state.tenantId}></Header>
                <Routes />
              </div>}</div>}

        <Modal
          show={this.state.loadingModalShow}
          centered
          dialogClassName="modal-transparent modal-90w"
          backdropClassName="modal-transparent-backdrop-noanimation"
          animation={false}
        >
          <ModalBody>
            <div className="text-center">
              <Loader
                type="Grid"
                color="#bababa"
                height="15%"
                width="15%"
              />
              <div className="mt-3">
                <FormattedMessage
                  id="app.home.loading"
                  defaultMessage="One moment"
                  description="Loading overlay text(while data is received from backend)"
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

    );
  }


}

function mapStateToProps(state) {
  return {
    tenant: state.tenantId
  };
}

export default connect(mapStateToProps)(App);
