import React from "react";
import { isChrome } from "react-device-detect";
import { FaCheckCircle, FaWeibo, FaWeixin } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

const offboarding = (deviceCount) => {
  return (
    <div>
      <div className="text-center">
        <FaCheckCircle id="icon-info" className="iconStyle-green text-center" />
      </div>

      <div className="text-center mt-3">
        <FormattedMessage
          id="app.offboarding.thankyou"
          defaultMessage="Your {deviceCount, plural, one {hearing aid is} other {hearing aids are}} successfully configured and ready to be used."
          description="Offboarding top message"
          values={{
            deviceCount: deviceCount,
          }}
        />
      </div>
      <div className="text-center mt-5">
        <FormattedMessage
          id="app.offboarding.followus"
          defaultMessage="For more information, please follow us"
          description="Offboarding follow us message"
        />
      </div>
      <div className="text-center mt-3">
        {isChrome ? (
          <img
            id="qrCodeImage"
            alt="wechat"
            src={require(`../Images/wechat-qr-code-medium.jpg`)}
          />
        ) : (
          <div>
            <a
              className="socialmediabox"
              href="https://weixin.qq.com/r/2DhWTrXEWegmrfEt923K"
              target="_blank" rel="noreferrer"
            >
              <FaWeixin
                id="icon-wechat"
                className="socialmediaicon text-center"
              />
              <div>
                <FormattedMessage
                  id="app.offboarding.wechat"
                  defaultMessage="Vibe Hearing Aids"
                  description="wechat channel description"
                />
              </div>
            </a>
            <a
              className="socialmediabox"
              href="https://weibo.com/u/7007120434"
              target="_blank" rel="noreferrer"
            >
              <FaWeibo
                id="icon-weibo"
                className="socialmediaicon text-center"
              />
              <div>
                <FormattedMessage
                  id="app.offboarding.weibo"
                  defaultMessage="Vibe-Hearing"
                  description="wechat channel description"
                />
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default offboarding;
