import React, { Component } from 'react'
import { Modal, ModalBody, ModalTitle, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class Support extends Component {
  render() {
    return (
      <React.Fragment>
        <Modal>
          <Modal.Header
            className="d-block text-center"
            style={{ borderBottom: "0 none" }}
          >
            <ModalTitle>
              <div id="supportHeader" className="txt-bold">
                <FormattedMessage
                  id="app.menu.support"
                  defaultMessage="Support"
                  description="Label text of support link in menu"
                /> asdf
              </div>
            </ModalTitle>
          </Modal.Header>
          <ModalBody style={{ maxHeight: "70vh", overflowY: "auto" }}>
            <div id="supportContent" className="text-center">
              <span>Vibe補聴器サポートセンター</span>
              <span>メール： <a href="mailto:support-jp@vibe-hearing.com">support-jp@vibe-hearing.com</a></span>
              <span>電話/Fax: 0570-033410</span>
              <span>(土・日・祝日・弊社休日を除く10:00-17:00)</span>
            </div>
          </ModalBody>
          <Modal.Footer
            className="d-block text-center"
            style={{ borderTop: "0 none" }}
          >
            <div className="text-center">
              <button
                className="largeButtonPopup"
                id="btnModalClose"
                type="button"
                onClick={this.handleSupportPopupClose}
              >
                <FormattedMessage
                  id="app.general.ok"
                  defaultMessage="OK"
                  description="Button caption for OK buttons"
                />
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Support