import React from "react";
import { FormattedMessage } from "react-intl";
import vibe_app_icon from "../Images/Vibe_app_icon.svg";
import androidStore from "../Images/Android.svg";
import appleStore from "../Images/Apple.svg";

const offboarding = (deviceCount) => {
  return (
    <div>
      <div>
        <img
          className="vibeAppIcon"
          src={vibe_app_icon}
        />
      </div>

      <div className="row mt-3" id="txt_jap_market_offboarding">
        <FormattedMessage
          id="app.offboarding.message.1"
          defaultMessage="お客様のVibe補聴器の初期設定が完了しました。"
          description="Offboarding message"
        />
        <FormattedMessage
          id="app.offboarding.message.2"
          defaultMessage="最後に、日常の環境に合わせて音量・音質の微調節ができるVibeアプリ（無料）をお使いのスマートフォンに合わせて、以下よりダウンロードしてください。"
          description="Offboarding message"
        />
      </div>
      <div className="appStoreButtonContainer">
        <a href="https://apps.apple.com/jp/app/vibe-app/id1483761159">
          <img src={appleStore} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.hicontrol.rta&hl=ja_JP">
          <img src={androidStore} />
        </a>
      </div>
    </div>
  );
};

export default offboarding;