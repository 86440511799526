import React, { Component } from 'react'
import { Modal, ModalBody, ModalTitle, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class Support extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal>
            <Modal.Header
            className="d-block text-center"
            style={{ borderBottom: "0 none" }}
          >
            <ModalTitle>
              <div id="supportHeader" className="txt-bold">
                <FormattedMessage
                  id="app.menu.support"
                  defaultMessage="Support"
                  description="Label text of support link in menu"
                />
              </div>
            </ModalTitle>
          </Modal.Header>
          <ModalBody style={{ maxHeight: "70vh", overflowY: "auto" }}>
            <div id="supportContent" className="text-center">
              <FormattedMessage
                id="app.support.information"
                defaultMessage="If you need assistance, please contact us at {phone} Our office hours are: {break} Monday to Friday {hours}"
                description="Button caption for OK buttons"
                values={{
                    phone: <div className="mt-1 mb-3">021-22313056</div>,
                    break: <div className="mt-1"></div>,
                    hours: <div className="mt-1">8:30 ~17:00</div>
                }}
              />
            </div> 
          </ModalBody>
          <Modal.Footer
            className="d-block text-center"
            style={{ borderTop: "0 none" }}
          >
            <div className="text-center">
              <button
                className="largeButtonPopup"
                id="btnModalClose"
                type="button"
                onClick={this.handleSupportPopupClose}
              >
                <FormattedMessage
                  id="app.general.ok"
                  defaultMessage="OK"
                  description="Button caption for OK buttons"
                />
              </button>
            </div>
          </Modal.Footer>
        </Modal>

</React.Fragment>

           
        );
    }
}

export default Support