import React, { Component } from 'react'

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="legalText text-justify legalContent">

                <span className="txt-bold legalTextParHeader">
                    Effective Date: October 1st, 2018
                </span>

                <div id="secA" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        A. Our Commitment to Data Privacy
                    </span>
                    Protecting the security and privacy of Personal Data is important to us. We conduct our business in compliance with applicable laws on data privacy protection and data security. We have created this Privacy Policy to demonstrate our commitment to every individual`s right to data protection and privacy. This Privacy Policy outlines how we handle information provided by you that can directly or indirectly identify you or (in the case where you are a healthcare provider, audiologist or similar or seller) that can directly or indirectly identify your customer, patient or end-user (collectively "Personal Data").
                    <div className="mt-1" />
                    Our global data privacy compliance program also consists of the implementation of unambiguous authorizations and consents, EU Standard Contractual Clauses, model contracts/transfer agreements, privacy principles, privacy statements, privacy policies and country-specific registrations and filings. This Privacy Policy covers the Web site www.sivantos.com, and all other applicable Sivantos web sites (individually “Web Site” and collectively “Web Sites”) as well as software applications (‘Applications’) provided by Sivantos and services provided by Sivantos through the Web Sites (‘Services’).
                </div>

                <div id="secB" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        B.	When does this Privacy Policy apply?
                    </span>
                    This Privacy Policy applies to Personal Data (e.g. name, address, telephone number or email address) that you provide to us or which is derived from such Personal Data as outlined below.
                    <div className="mt-1" />
                    In the event of any inconsistency between the English and non-English version of this Privacy Policy (where translated for local requirements), the English version of this Privacy Policy shall govern, to the extent permitted by applicable laws.
                </div>

                <div id="secC" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        C.	Data Controller
                    </span>
                    The data controller of the Web Sites is Sivantos. Where a registration or consent form is presented on our Web Sites or in your use of any of our Applications or Services, the data controller may vary and may be an affiliate or subsidiary of Sivantos (collectively ‘Sivantos’) or yourself depending on the actual offering or the purpose of the data collection and may be displayed on the applicable registration or consent form. For inquiries you may contact our Data Protection Officer at dpo@sivantos.com.
                </div>

                <div id="secD" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        D.	Data Processor (processing of patient data)
                    </span>
                    In cases where Sivantos is a data processor, the use of information collected through e.g. Sivantos Software (Mobile or Desktop) shall be limited to the purpose of providing the service for which Sivantos is engaged for.
                    <div className="mt-1" />
                    Sivantos might have no direct relationship with the individuals whose personal data it processes, e.g. where you are a patient of one of our partners (“Audiologist”) and would no longer like to be contacted by your Audiologist that use our service, please contact the Audiologist that you interact with directly. We may transfer personal data to companies that help us provide our service. Transfers to subsequent third parties are covered by the T&C with our business partners.
                    <div className="mt-1" />
                    Your Audiologist will respond to reasonable requests to review your personal data and to correct, amend or delete any inaccuracies. If requested to remove data we will respond within a reasonable timeframe. If you have any questions or comments about this Privacy Policy (e.g. to review and update your personal data), please send your questions and feedback to your Audiologist. If you have a complaint about how your personal data is handled, you may contact your Audiologist, and the responsible data privacy personnel shall contact you within a reasonable timeframe.
                    <div className="mt-1" />
                    We will retain personal data we process on behalf of our Audiologists for as long as needed to provide services to the Audiologists. Sivantos will retain this personal data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                    <div className="mt-1" />
                    You may revoke your consent to the collection and processing of your personal data at any time with effect for the future by written notification to your Audiologist. Please note that deleting the App does not delete your account. You must contact your Audiologist to delete your registration and data. You may also send us an email at apps@sivantos.com to request access to, correct, or delete any personal data that you have provided to us.  If you choose to delete your account, you will not be able to take advantage of all the functions of the App, including certain personalized features.  We may not accommodate a request to change data if we believe the change would violate any law or legal requirement or cause the data to be incorrect.  In addition, your Audiologist may be required under applicable law to retain a copy of data as part of your patient file.
                </div>

                <div id="secE" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        E.	Why do I need to provide Personal Data?
                    </span>
                    As a general principle, your granting of any consent and your provision of any Personal Data hereunder is entirely voluntary and which you may revoke at any time as provided in this Privacy Policy. In the case of information that can be used to directly or indirectly identify your customer, patient or end-user, you warrant and confirm that you have the express consent of such persons to provide their Personal Data to us upon the terms of this Privacy Policy and any terms of use or license for which you agree to in connection with such disclosure.
                    <div className="mt-1" />
                    We do not collect Personal Data through our Web Sites and Applications unless you voluntarily allow us to do so (e.g. by way of registrations, surveys), provide us with your consent or unless otherwise permitted by applicable laws and regulations for the protection of such Personal Data.
                </div>
             
                <div id="secF" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        F.	What is the Personal Data used for?
                    </span>
                    We will process the Personal Data only as set out in this Privacy Policy, your registration form, consent or applicable terms of use or license. Further information can be found in the Purpose of Use section below.
                </div>

                <div id="secG" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        G.	Duration of processing of Personal Data.
                    </span>
                    Where we are processing and using Personal Data pursuant this Privacy Policy or as permitted by law or contractually, we will store such Personal Data (i) only for as long as is required to fulfil the purposes set out in this Privacy Policy, as required by law or contractually or (ii) until you object to our continued use of such Personal Data (in the case where we have a legitimate interest in using such Personal Data), or (iii) until you withdraw your consent (in the case where you had consented to us using such Personal Data). However, where retention of such Personal Data for longer is required by us to comply with the law or regulations or where such Personal Data is required for us to assert or defend against legal claims, we will retain such Personal Data until the end of the relevant retention period or until the claims in question have been settled.
                </div>

                <div id="secH" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        H.	Where will Personal Data be processed?
                    </span>
                    As part of a global group of companies, we have affiliates and subsidiaries within as well as outside of the European Economic Area (the “EEA”). Therefore, whenever we use or process the Personal Data for the purposes set out in this Privacy Policy, we may transfer such Personal Data to our affiliates and subsidiaries located in countries outside of the EEA including to such countries in which a statutory level of data protection applies that is not comparable to the level of data protection within the EEA. Whenever such transfer occurs, it is based on Intercompany Agreements that are based on the Standard Contractual Clauses (according to EU Commission Decision 87/2010/EC or any future replacement) to contractually provide that such Personal Data is subject to a level of data protection that applies within the EEA.
                    <div className="mt-1" />
                    We may use third party service providers to process Personal Data for the purposes set out in this Privacy Policy. Such third party service providers may not be in located in your country. In any case, we will have contractual agreements in place with them for their processing of Personal Data in accordance with this Privacy Policy and applicable data protection laws.
                </div>

                <div id="secI" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        I.	What are my rights?
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        In General
                    </span>
                    You can at any time request from us information about whether we hold any of your personal data and about which of the Personal Data we are processing as well as the correction or deletion of such Personal Data by sending your request to apps@sivantos.com. Please note, however, that we can delete such Personal Data only if there is no statutory obligation or prevailing right for us to retain it. Kindly note that if you request us to delete such Personal Data, you will not be able to continue to use the applicable Web Sites, Applications, Services or any related Sivantos services that requires Sivantos’ use of such Personal Data. We will respond to your request within a reasonable timeframe.
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        Request a copy
                    </span>
                    If we use the Personal Data based on your consent or to perform a contract with you, you may request from us for a copy of Personal Data that you had provided. In this case, please contact us at apps@sivantos.com and specify the Personal Data and the format in which you would like to receive the Personal Data.
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        Right to be forgotten / Restriction of use.
                    </span>
                    You may request us to restrict any processing of the Personal Data in any of the following events: (i) you inform us that the Personal Data that we have is incorrect, (in such cases we may continue to retain such of the Personal Data to check its accuracy), (ii) that we have no legal basis to process such Personal Data, or (iii) you object to our processing of such Personal Data based on our legitimate interest as set out in Section L below.
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        Right to lodge a complaint
                    </span>
                    If you have reason to believe that we are not processing the Personal Data in accordance with the requirements of this Privacy Policy or applicable EEA data protection laws, you may choose to lodge a complaint with the data protection authority of the EEA country in which you live or with the relevant data protection authority of the country or state in which applicable Sivantos entity is located.
                </div>

                <div id="secH" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        J.	Further Restrictions and Specific Provisions
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        Use of this website by children.
                    </span>
                    This website is not intended for anyone under the age of 16 years. If you are younger than 16, you may not register with or use this website.
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        Links to other websites.
                    </span>
                    This website may contain links to foreign (meaning non-Sivantos companies) websites. Sivantos is not responsible for the privacy practices or the content of websites outside Sivantos. Therefore, we recommend that you carefully read the privacy statements of such foreign sites.
                </div>


                <div id="secH" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        K.	Use of technical measures, e.g. Cookies etc.
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        Non-Personal Data Collected Automatically
                    </span>
                    When you access our Web Sites or use our Applications or Services, we may automatically (i.e., not by registration) collect non- personal data (e.g. type of Internet browser and operating system used, domain name of the Web Sites from which you came, number of visits, average time spent on the site, pages viewed). We may use such data and share it with its affiliates and subsidiaries to improve the performance, content or appearance of the Web Sites, Applications and/or Services.
                    <div className="mt-1" />
                    When you download and use our Services via mobile app, we automatically collect information on the type of device you use, operating system version, and the device identifier (or "UDID").
                    <div className="mt-1" />
                    We also use mobile analytics software to allow us to better understand the functionality of our mobile software on your phone. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data. We do not link the information we store within the analytics software to any personal data you submit within the mobile application.
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        “Cookies” – Information Stored Automatically on Your Computer
                    </span>
                    When you use our Web Sites, Applications and/or Services, we and/or our business partners may store some data on your computer in the form of a “cookie” or similar technologies to automatically recognize your computer next time you use our Web Sites, Applications and/or Services. Cookies can help us in many ways, for example, by allowing us to tailor our Web Site, Applications and/or Services to better match your interests, to analyze trends, track users’ movements around the website, to gather demographic information about our user base as a whole or to store your password to save you having to re-enter it each time. If you do not wish to receive cookies, please configure your Internet browser to erase all cookies from your computer’s hard drive, block all cookies or to receive a warning before a cookie is stored. For more details in how we use Cookies and how to opt-out please refer to our Cookie Policy.
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        Google Analytics
                    </span>
                    Our Web Sites makes use of Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use our Web Sites. The information generated by the cookie about your use of our Web Sites will generally be transmitted to and stored by Google on servers in the United States. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA, where it will be abbreviated. At our request, Google will use this information for purpose of evaluating your use of our Web Sites, compiling reports on our Web Sites activity for Web Site operators and providing other services relating to Web Site activity and internet usage. The IP address transmitted by your browser by Google Analytics will not be associated with other Google data. You may refuse the use of cookies by selecting the appropriate settings on your browser, however, please note that if you do this you may not be able to use the full functionality of our Web Sites. In addition, you can prevent the collection of the data generated by the cookie and associated with your use of our Web Sites (including your IP address) by Google, as well as the processing of this data by Google, by downloading and installing the browser plug-in available from the following link: http://tools.google.com/dlpage/gaoptout.
                </div>

                <div id="secL" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        L.	Security
                    </span>
                    To protect Personal Data against accidental or unlawful destruction, loss or alteration and against unauthorized disclosure or access, we use technical and organizational security measures.
                </div>


                <div id="secM" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        M.	Purpose of Use
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold">
                        In the following cases, we are permitted to process Personal Data under the applicable data protection laws.
                    </span>
                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        a)	Providing the requested goods or services.
                    </span>
                    If you order goods or services from us, we will use the Personal Data (e.g. name, (email) address, telephone number, company name and address credit card number or bank details) only to process your order or to provide the requested goods or service. This may also include conversation data that you may trigger via the chat functionalities, contact forms, emails, or telephone. In this Privacy Policy, “goods and services” includes Sivantos hearing aids and related services, web services, offerings, contests, other content, non-marketing related newsletters, tutorials, trainings and events.

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        b)	Communication.
                    </span>
                    We communicate on a regular basis by email with users who use our goods or related services, and we may also communicate by phone to resolve customer complaints or investigate suspicious transactions. We may use your email address to confirm your opening of an account, to send you notice of payments, to send you information about changes to our goods and services, and to send notices and other disclosures as required by law. Generally, users cannot opt out of these communications, which are not marketing-related but merely required for the relevant business relationship.

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        c)	Compliance.
                    </span>
                    Furthermore, you acknowledge that any information required to track your choices regarding the processing or use of Personal Data or receipt of marketing materials may be stored and exchanged between members of Sivantos to ensure compliance.

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        d)	Sivantos’ legitimate interest.
                    </span>
                    Each of the use cases in sub-paragraphs e) to g) below constitutes our legitimate interest to process or use the Personal Data. If you do not agree with this approach, you may object against our processing or use of such Personal Data as set out below.

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        e)	Questionnaires and surveys.
                    </span>
                    We may invite you to participate in questionnaires and surveys. These questionnaires and surveys will be generally designed in a way that they can be answered without any Personal Data. If you nonetheless enter Personal Data in a questionnaire or survey, we may use such Personal Data to improve Sivantos goods and services.

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        f)	Creation of aggregated data sets.
                    </span>
                    We may aggregate Personal Data provided under this Privacy Statement to create aggregated data sets, which will then be used to improve Sivantos goods and services.

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        g)	Recording of calls and chats for quality improvement purposes.
                    </span>
                    In case of telephone calls or chat sessions, we may record such calls (after informing you accordingly during that call and before the recording starts) or chat sessions to improve the quality of our services.

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        h)	Right to object.
                    </span>
                    You may object to our using Personal Data for the above purposes at any time. If you do so, we will cease using your Personal Data for the above purposes and remove it from its systems unless we are permitted to use such Personal Data for another purpose set out in this Privacy Statement or we determine and demonstrate a compelling legitimate interest to continue processing your Personal Data (in cases where this is required by mandatory local laws, e.g. for tax purposes etc.).


                    <div className="mt-1" />
                    <span className="txt-bold">
                        In the following cases we will only use Personal Data as further detailed below after you have granted us prior consent.
                    </span>

                    <div className="mt-1" />
                    <span className="txt-bold legalTextSubParHeader">
                        News about Sivantos’ Products and Services.
                    </span>
                    We may use your name, email and postal address, telephone number or (in the case where you are a healthcare provider, audiologist or similar or seller) the name, email and postal address, telephone number of your customer, patient or end-user, and basic information about your employer (name and address) to keep you or (in the case where you are a healthcare provider, audiologist or similar or seller) your customer, patient or end-user up to date on the latest product announcements and other information about Sivantos’ goods and services (including marketing-related newsletters) as well as events of Sivantos and to display relevant content on our Web Sites. If you would like to discontinue receiving this information, you may update your email preferences by using the “Unsubscribe” link found in emails we send to you.

                    <div className="mt-1" />
                    <span className="txt-bold">
                        Purpose Limitation
                    </span>
                    <div className="mt-1" />
                    Sivantos will collect, use or disclose personal data supplied by You through the Sivantos applications only for the purposes disclosed to You, unless the disclosure:
                    <div className="mt-1" />
                    <ul>
                        <li>is necessary for maintenance and to ensure the proper functionality of the Sivantos software,</li>
                        <li>is required by law or the competent governmental or judicial authorities, such as to comply with a subpoena or other legal process,</li>
                        <li>is necessary to protect our rights, protect Your safety or the safety of others, investigate fraud, or respond to a government request</li>
                        <li>is necessary to establish or preserve a legal claim or defense, or</li>
                        <li>is necessary to prevent fraud or other illegal activities, such as willful attacks on Sivantos’ information technology systems.</li>
                    </ul>

                    <div className="mt-1" />
                    In certain situations, Sivantos may be required to disclose personal data in response to lawful requests by public authorities, including to meet law enforcement requirements as applicable.
                </div>

                <div id="secL" className="mt-1">
                    <span className="txt-bold legalTextParHeader">
                        N.	Questions and Comments
                    </span>
                    We will respond to reasonable requests to review the Personal Data and to correct, amend or delete any inaccuracies. Please contact us at apps@sivantos.com for such requests.
                    <div className="mt-1"/>
                    If you have a complaint about how Personal Data is handled by us, you may contact the person, department or office that you have been dealing with. You can alternatively contact our Data Privacy Officer, dpo@sivantos.com and the responsible data privacy personnel shall contact you within a reasonable timeframe.
                    <div className="mt-1"/>
                    If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at https://feedback-form.truste.com/watchdog/request.
                    <div className="mt-1"/>
                    This Privacy Policy may be updated and amended from time to time. Please check this page regularly to keep up-to-date. Your continued or subsequent access or use of our Web Sites, Applications and/or Services shall be deemed to be your acceptance of the amended or modified Privacy Policy. In accessing or using our Web Sites, Applications and/or Services, the Privacy Policy is accepted by you in its then-current version. If you do not agree to the Privacy Policy, please do not use our Web Sites, Applications and/or Services. If we make any material changes we will notify you by email (sent to the email address specified in your account) or by means of a notice on this website prior to the change becoming effective.
                    <div className="mt-1"/>

                    <span className="txt-bold">Sivantos Pte. Ltd.</span>
                    <br/>
                    <span className="txt-bold">18 Tai Seng Street</span>
                    <br/>
                    <span className="txt-bold">Singapore</span>
                    <br/>
                    <span className="txt-bold">dpo@sivantos.com</span>
                </div>
            </div>
        )
    }
}
export default PrivacyPolicy