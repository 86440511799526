import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

const offboarding = (deviceCount) => {
  return (
    <div>
      <div className="text-center">
        <FaCheckCircle id="icon-info" className="iconStyle-green text-center" />
      </div>

      <div className="text-center mt-3">
        <FormattedMessage
          id="app.offboarding.thankyou"
          defaultMessage="Your {deviceCount, plural, one {hearing aid is} other {hearing aids are}} successfully configured and ready to be used."
          description="Offboarding top message"
          values={{
            deviceCount: deviceCount,
          }}
        />
      </div>
      <div className="text-center mt-3"></div>
    </div>
  );
};

export default offboarding;