import React, { Component } from 'react';
import { FaCheck } from 'react-icons/fa'
import {connect} from 'react-redux';


class HeariingInstrument extends Component {

    constructor(props) {
        super(props);
        this.getHiImage = this.getHiImage.bind(this);
        this.state = {
            hiImage: ""
        }
    }

    getHiImage(uri, sideIdentifier) {
        var uriWithIdentifier = uri + sideIdentifier;
        switch (uriWithIdentifier) {
            case 'SIV1:0/18/262064064/1LEFT': return require(`../Assets/Images/aids/SIV10182620640641_L.jpg`);
            case 'SIV1:0/18/262064064/1RIGHT': return require(`../Assets/Images/aids/SIV10182620640641_R.jpg`);

            case 'SIV1:0/18/242065032/1LEFT': return require(`../Assets/Images/aids/SIV10182420650321_L.jpg`);
            case 'SIV1:0/18/242065032/1RIGHT': return require(`../Assets/Images/aids/SIV10182420650321_R.jpg`);

            case 'SIV1:0/18/242064034/1LEFT': return require(`../Assets/Images/aids/SIV10182420640341_L.jpg`);
            case 'SIV1:0/18/242064034/1RIGHT': return require(`../Assets/Images/aids/SIV10182420640341_R.jpg`);

            case 'SIV1:0/18/242064033/1LEFT': return require(`../Assets/Images/aids/SIV10182420640331_L.jpg`);
            case 'SIV1:0/18/242064033/1RIGHT': return require(`../Assets/Images/aids/SIV10182420640331_R.jpg`);

            case 'SIV1:0/18/222066064/1LEFT': return require(`../Assets/Images/aids/SIV10182220660641_L.jpg`);
            case 'SIV1:0/18/222066064/1RIGHT': return  require(`../Assets/Images/aids/SIV10182220660641_R.jpg`);

            case 'SIV1:0/18/242065033/1LEFT': return require(`../Assets/Images/aids/SIV10182420650331_L.jpg`);
            case 'SIV1:0/18/242065033/1RIGHT': return  require(`../Assets/Images/aids/SIV10182420650331_R.jpg`);

            case 'SIV1:0/18/20054D064/1LEFT': return  require(`../Assets/Images/aids/SIV101820054D0641_L.jpg`);
            case 'SIV1:0/18/20054D064/1RIGHT': return  require(`../Assets/Images/aids/SIV101820054D0641_R.jpg`);

            case 'SIV1:0/18/20034D065/1LEFT': return  require(`../Assets/Images/aids/SIV101820034D0651_L.jpg`);
            case 'SIV1:0/18/20034D065/1RIGHT': return  require(`../Assets/Images/aids/SIV101820034D0651_R.jpg`);

            case 'SIV1:0/18/6144007/1LEFT': return  require(`../Assets/Images/aids/SIV101861440071_L.jpg`);
            case 'SIV1:0/18/6144007/1RIGHT': return  require(`../Assets/Images/aids/SIV101861440071_R.jpg`);

            case 'SIV1:0/18/GO/1LEFT': return  require(`../Assets/Images/aids/SIV1018GO1_L.jpg`);
            case 'SIV1:0/18/GO/1RIGHT': return  require(`../Assets/Images/aids/SIV1018GO1_R.jpg`);

            case 'SIV1:0/18/12348358706/1LEFT': return  require(`../Assets/Images/aids/SIV1018123483587061_L.jpg`);
            case 'SIV1:0/18/12348358706/1RIGHT': return  require(`../Assets/Images/aids/SIV1018123483587061_R.jpg`);

            case 'SIV1:0/18/12348395570/1LEFT': return  require(`../Assets/Images/aids/SIV1018123483955701_L.jpg`);
            case 'SIV1:0/18/12348395570/1RIGHT': return  require(`../Assets/Images/aids/SIV1018123483955701_R.jpg`);

            case 'SIV1:0/18/72022521809666V/1LEFT': return  require(`../Assets/Images/aids/SIV101872022521809666V1_L.jpg`);
            case 'SIV1:0/18/72022521809666V/1RIGHT': return  require(`../Assets/Images/aids/SIV101872022521809666V1_R.jpg`);

            case 'SIV1:0/18/72022521809666C/1LEFT': return  require(`../Assets/Images/aids/SIV101872022521809666C1_L.jpg`);
            case 'SIV1:0/18/72022521809666C/1RIGHT': return  require(`../Assets/Images/aids/SIV101872022521809666C1_R.jpg`);

            default: return "";
        }
    }

    render() {
        const { hi, identifier, name, configured, tenantId } = this.props;      
        return (
            <div>
                <div className="outer-Overlay">
                     <img className={configured ? 'hiImage opacity5' : 'hiImage' } alt="hi_image" 
                    src={`${this.getHiImage(hi, identifier)}`} /> 
                    
                    {configured ? 
                        <div className="inner-Overlay">
                            <FaCheck id="successImg" className="configuredCheckmark" />
                        </div> 
                    : null}
                </div>

                <div className="txt-bold mt-3">
                    <h4 id="hiName">{name}</h4>
                </div>
            </div>
        );
    }

}
function mapStateToProps(state){
    return {
   tenant: state.tenantId
    };
  }
  
export default connect(mapStateToProps)(HeariingInstrument);
  


